import type { HttpError, HttpRequestOptions } from '@pepita/http';
import { http as pepitaHttp } from '@pepita/http';
import * as Sentry from '@sentry/nextjs';

export type { HttpError, HttpRequestOptions, HttpResponse } from '@pepita/http';

const clientErrorFormatter = (errorBody?: string) => {
  try {
    return JSON.parse(errorBody || '{}');
  } catch (e) {
    return errorBody;
  }
};

const onError = (err: HttpError) => {
  const scope = Sentry.getCurrentHub().getScope();

  if (scope) {
    if (err.type === 'StatusError') {
      scope
        .setContext('Client Request', err.request)
        .setContext('Client Response', clientErrorFormatter(err.body))
        .setFingerprint(['HttpClientErrorBrowser']);
    } else if (err.type === 'ConnectionError') {
      scope.setFingerprint(['HttpClientIgnoreSentry']);
    }
  }
};

export const http = (url: string, options: HttpRequestOptions = {}) =>
  pepitaHttp(url, options, onError);

http.get = (url: string, options: HttpRequestOptions = {}) =>
  pepitaHttp.get(url, options, onError);
http.post = (url, options: HttpRequestOptions = {}) =>
  pepitaHttp.post(url, options, onError);
http.delete = (url, options: HttpRequestOptions = {}) =>
  pepitaHttp.delete(url, options, onError);
