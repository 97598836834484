import { LISTING_TYPE } from 'src/constants/real-estate';

import {
  formatVisibility,
  getBooleanString,
  getContractType,
  isCourtAgency,
  TrackingDataAdvertiser,
} from 'src/tracking/utils';

import type { RealEstate } from 'src/types/real-estate';
import type { ListRealEstate } from 'src/types/real-estate-list';
import type { RealEstateLite } from 'src/types/real-estate-lite';

import type { Trans } from 'src/utils/i18nFormatters';

import { getListingType } from '.';
interface FormatRealEstateTrackingDataArgs {
  productIdChild?: number;
  realEstate: (RealEstate | ListRealEstate | RealEstateLite) & {
    currentPage?: number;
    positionList?: number;
    origin?: 'Detail' | 'List' | 'Map';
  };
  trans: Trans;
}

export const formatGA4RealEstateTrackingData = ({
  productIdChild,
  realEstate,
  trans,
}: FormatRealEstateTrackingDataArgs) => {
  const mainProperty = realEstate.properties[0];
  const contractType = realEstate.contract;
  const location = mainProperty.location;

  const contractTranslation = trans('lbl_for', {
    capitalize: true,
    context: realEstate.contract,
  });

  const { advertiser, id: realEstateId, price, luxury } = realEstate;

  const {
    category,
    rooms,
    bathrooms,
    floor,
    bedRoomsNumber,
    elevator: hasElevators,
    energy,
    typologyGA4Translation,
    ga4features,
    ga4Condition,
  } = mainProperty;

  const sectionForLogin = advertiser?.agency
    ? 'Dettaglio_Agenzia'
    : 'Dettaglio_Annuncio';

  const advertiserCustomType = advertiser?.agency
    ? advertiser.agency.customType
    : null;

  const isCourt = isCourtAgency(advertiser?.agency?.type);

  return {
    id: realEstate.id,
    advertiser: realEstate.advertiser?.agency
      ? TrackingDataAdvertiser.AGENCY
      : TrackingDataAdvertiser.PRIVATE,
    productIdChild,
    productContentCategory: contractTranslation,
    productDestination: category?.name || null,
    productListingType: advertiser
      ? getListingType(contractType, advertiser)
      : null,
    currency: 'EUR' as const,
    sectionForLogin,
    property_type: typologyGA4Translation,
    property_price: price.value ?? undefined,
    property_id: realEstateId,
    property_country: location?.nation?.name ?? '',
    property_contract: getContractType(realEstate.contract),
    property_region: location?.region,
    property_province: location?.province,
    property_city: location?.city,
    property_macrozone: location?.macrozone,
    property_microzone: location?.microzone || undefined,
    agency_id: isCourt ? '210238' : advertiser?.agency?.id.toString(), // id generico usato da app per enti e tribunali
    agency_name: isCourt ? 'Enti e tribunali' : advertiser?.agency?.displayName,
    courthouse_id: isCourt ? advertiser?.agency?.id : undefined,
    courthouse_name: isCourt ? advertiser?.agency?.displayName : undefined,
    property_is_luxury: getBooleanString(luxury),
    property_rooms: rooms ? rooms.replace(/\s/g, '') : undefined,
    property_baths: bathrooms,
    property_floor: floor?.ga4FloorValue,
    property_bedrooms: bedRoomsNumber,
    property_has_elevator: getBooleanString(hasElevators),
    property_heating: energy?.GA4Heating ? energy.GA4Heating : 'Assente',
    property_otherfeatures:
      ga4features && ga4features.length > 0 ? ga4features.join('|') : undefined,
    property_state: ga4Condition,
    property_visibility: formatVisibility(realEstate.visibility),
    property_is_auction: getBooleanString(
      realEstate.type === LISTING_TYPE.AUCTION
    ),
    property_is_project: getBooleanString(realEstate.isProjectLike),
    property_id_child: productIdChild ?? undefined,
    property_price_range: price.priceRange,
    property_garage: mainProperty.ga4Garage,
    admanager_advertiser: advertiserCustomType ?? undefined,
    origin: realEstate.origin,
  };
};
