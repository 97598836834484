import { useTranslations } from '@pepita-react/i18n';

import css from './styles.module.scss';

export function ErrorBoundaryMap() {
  const { trans } = useTranslations();

  return (
    <div className={css['ag-mapError']}>
      {trans('lbl_generic_error', { capitalize: true })}
    </div>
  );
}
