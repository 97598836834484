import { isFeatureEnabled } from 'src/config/features-toggle';

const sortCriterionValues = [
  'automatico',
  'rilevanza',
  'superficie',
  'prezzo',
  'locali',
  'dataModifica',
  'data',
] as const;

export type SortCriterion = (typeof sortCriterionValues)[number];

const DEFAULT_SORT_BY = isFeatureEnabled('LISTING_SEARCH_NEW_SORTING')
  ? 'rilevanza'
  : 'automatico';

const isSortCriterion = (sortingValue: string): sortingValue is SortCriterion =>
  (sortCriterionValues as readonly string[]).includes(sortingValue);

/**
 * Get the sort criterion from the query string.
 */
export const getSortCriterion = (
  qsSortingValue: string | string[] | undefined
) => {
  if (!qsSortingValue) return DEFAULT_SORT_BY;

  if (Array.isArray(qsSortingValue)) {
    return qsSortingValue.find(isSortCriterion) || DEFAULT_SORT_BY;
  }

  return isSortCriterion(qsSortingValue) ? qsSortingValue : DEFAULT_SORT_BY;
};
