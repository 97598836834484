import type { PropsWithChildren } from 'react';

import { useSetLayoutMode } from '../hooks';

import css from './styles.module.scss';

export function Main({ children }: PropsWithChildren) {
  useSetLayoutMode();

  return <main className={css['in-searchLayoutMain']}>{children}</main>;
}
