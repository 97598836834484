import type { RefObject } from 'react';
import { useEffect } from 'react';
import { useAtomValue } from 'jotai';

import { expandedMapAtom, layoutModeAtom } from '../atoms';

import type { PepitaStripeHandle } from 'src/libs/ui/pepita-stripe';

export const useUpdateStripeVisibleSlidesCount = (
  stripeRef: RefObject<PepitaStripeHandle>
) => {
  const expandedMap = useAtomValue(expandedMapAtom);
  const layoutMode = useAtomValue(layoutModeAtom);

  useEffect(() => {
    if (layoutMode === 'map-alternates-with-the-list') return;

    stripeRef.current?.updateVisibleSlidesCount();
  }, [stripeRef, expandedMap, layoutMode]);
};
