import { useAtomValue } from 'jotai';

import {
  expandedMapAtom,
  layoutModeAtom,
} from 'src/entities/search/components/SearchLayout';

export const useIsMapCollapsed = () => {
  const layoutMode = useAtomValue(layoutModeAtom);
  const isExpandedMap = useAtomValue(expandedMapAtom);

  // In mobile view, the map is always collapsed
  if (layoutMode === 'map-alternates-with-the-list') {
    return false;
  }

  return !isExpandedMap;
};
