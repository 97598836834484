import {
  getCommonTrackingData,
  setCommonTrackingData,
} from '@indomita-react/segment';
import { sha256 } from 'js-sha256';

import { isFeatureEnabled } from 'src/config/features-toggle';
import { getProductConfig } from 'src/config/product';

import type { AGENCY_TYPE } from 'src/constants/real-estate';
import {
  CATEGORY,
  CONDITION,
  CONTRACT,
  FLOOR_TYPE,
  GARAGE_TYPE,
  GARDEN_TYPE,
  HEATING_TYPE,
} from 'src/constants/real-estate';
import {
  CATEGORIES,
  CONTRACT as CONTACTSTRING,
  ROOM_CATEGORIES,
  TYPOLOGIES,
  TYPOLOGIES_NEW_BUILDINGS,
} from 'src/constants/typologies';

import { getSortCriterion } from 'src/entities/search/utils/sorting';

import type { GeographySearch } from 'src/libs/geography';

import { ENTITY_TYPE } from 'src/types/geography';
import type { Price, Visibility } from 'src/types/real-estate';
import type { SearchParams } from 'src/types/search';
import type { HashEmailAndPhoneArgs, URLParts } from '../types';

import { formatCircleParams } from 'src/utils/formatCircleParams';

import { REAL_ESTATE_TRACKING_SECTIONS } from 'src/views/RealEstate/hooks/useRealEstateTracking';

import type { SegmentStoreDetailTrackingData } from '../segment/types';

import {
  CATEGORIES_VALUE,
  CONDITION_VALUE,
  CONTACT_TYPES,
  EmailAndPhoneStorageKey,
  EntryPoint,
  FLOOR_VALUE,
  GARAGE_VALUE,
  GARDEN_VALUE,
  HEATING_VALUE,
  OTHER_FEATURES_SEARCH_VALUE,
  PurchaseAndContactEventValue,
  REVENUES,
  TYPOLOGIES_VALUE,
} from './enums';

const maxResult = getProductConfig('mapMaxResultsPerPage');
const bedroomSearchEnabled = isFeatureEnabled('BEDROOM_SEARCH_ENABLED');

export const getBooleanString = (value?: boolean | string | number | null) =>
  value ? 'si' : 'no';

export const isCourtAgency = (type?: AGENCY_TYPE) => {
  return type === 'court';
};

export enum TrackingDataAdvertiser {
  AGENCY = 'agenzia',
  PRIVATE = 'privato',
}

export const getContractType = (
  contractType?: CONTRACT | CONTACTSTRING | number | string
) => {
  if (!contractType) {
    return;
  }

  if (
    contractType === CONTRACT.SALE ||
    contractType === CONTACTSTRING.VENDITA
  ) {
    return 'Vendita';
  }

  if (
    contractType === CONTRACT.AUCTION ||
    contractType === CONTACTSTRING.ASTE
  ) {
    return 'Vendita';
  }

  if (
    contractType === CONTRACT.RENT ||
    contractType === CONTACTSTRING.AFFITTO
  ) {
    return 'Affitto';
  }

  return;
};

export const mappingContractTypeId = new Map([
  [CONTRACT.SALE, '1'],
  [CONTRACT.RENT, '2'],
  [CONTRACT.AUCTION, '14'],
]);

export const getCategoryByCategoryId = (categoryId?: string) => {
  if (!categoryId) return undefined;

  switch (parseInt(categoryId)) {
    case CATEGORY.RESIDENTIAL:
      return CATEGORIES_VALUE.RESIDENTIAL;
    case CATEGORY.ROOMS:
      return CATEGORIES_VALUE.ROOMS;
    case CATEGORY.NEW_CONSTRUCTIONS:
      return CATEGORIES_VALUE.NEW_CONSTRUCTIONS;
    case CATEGORY.BOX_GARAGE:
      return CATEGORIES_VALUE.BOX_GARAGE;
    case CATEGORY.PALACE:
      return CATEGORIES_VALUE.PALACE;
    case CATEGORY.COWORKING:
      return CATEGORIES_VALUE.COWORKING;
    case CATEGORY.SHOPS:
      return CATEGORIES_VALUE.SHOPS;
    case CATEGORY.DEPOSITS:
      return CATEGORIES_VALUE.DEPOSITS;
    case CATEGORY.SHEDS:
      return CATEGORIES_VALUE.SHEDS;
    case CATEGORY.LANDS:
      return CATEGORIES_VALUE.LANDS;
    default:
      return undefined;
  }
};

export const getConditionByFilter = (id?: number[] | string[]) => {
  if (id) {
    const idValue = id.toString();

    if (idValue === CONDITION.GOOD_OR_LIVABLE) {
      return CONDITION_VALUE.GOOD_OR_LIVABLE;
    } else if (idValue === CONDITION.RENOVATE) {
      return CONDITION_VALUE.RENOVATE;
    } else if (idValue === CONDITION.NEW_OR_UNDER_CONSTRUCTION) {
      return CONDITION_VALUE.NEW_OR_UNDER_CONSTRUCTION;
    }

    return CONDITION_VALUE.BEST_OR_RENOVATED;
  }

  return undefined;
};

export const getFloorByFilter = (floor?: number[] | string[]) => {
  if (floor) {
    const floors = Array.isArray(floor) ? floor : [floor];

    const valueFloors = floors.map((id: { id?: number | string }) => {
      const idValue = id.toString();

      if (idValue === FLOOR_TYPE.GROUND) {
        return FLOOR_VALUE.GROUND;
      }

      if (idValue === FLOOR_TYPE.INTERMEDIATE) {
        return FLOOR_VALUE.INTERMEDIATE;
      }

      if (idValue === FLOOR_TYPE.TOP) {
        return FLOOR_VALUE.TOP;
      }
    });

    return valueFloors.join('|');
  }

  return undefined;
};

export const getGarageByFilter = (garageId?: string[] | number[]) => {
  if (garageId) {
    const garageIds = Array.isArray(garageId) ? garageId : [garageId];
    const valueGarage = garageIds.map((id: { id?: number | string }) => {
      const idValue = id.toString();

      if (idValue === GARAGE_TYPE.SINGLE) {
        return GARAGE_VALUE.SINGLE;
      }

      if (idValue === GARAGE_TYPE.DOUBLE) {
        return GARAGE_VALUE.DOUBLE;
      }

      if (idValue === GARAGE_TYPE.PARKING_SPACE) {
        return GARAGE_VALUE.PARKING_SPACE;
      }
    });

    return valueGarage.join('|');
  }

  return undefined;
};

export const getGardenByFilter = (gardenId?: string[] | number[]) => {
  if (gardenId) {
    const gardenIds = Array.isArray(gardenId) ? gardenId : [gardenId];
    const valueGarden = gardenIds.map((id: { id?: number | string }) => {
      const idValue = id.toString();

      if (idValue === GARDEN_TYPE.SHARED) {
        return GARDEN_VALUE.SHARED;
      }

      if (idValue === GARDEN_TYPE.PRIVATE) {
        return GARDEN_VALUE.PRIVATE;
      }
    });

    return valueGarden.join('|');
  }

  return undefined;
};

export const getHeatingByFilter = (heatingId?: number[] | string[]) => {
  if (heatingId) {
    const heatingIds = Array.isArray(heatingId) ? heatingId : [heatingId];
    const valueHeating = heatingIds.map((id: { id?: number | string }) => {
      const idValue = id.toString();

      if (idValue === HEATING_TYPE.AUTONOMOUS) {
        return HEATING_VALUE.AUTONOMOUS;
      }

      if (idValue === HEATING_TYPE.AIR_CONDITIONING) {
        return HEATING_VALUE.AIR_CONDITIONING;
      }

      if (idValue === HEATING_TYPE.CENTRALIZED) {
        return HEATING_VALUE.CENTRALIZED;
      }
    });

    return valueHeating.join('|');
  }

  return undefined;
};

export const getOtherFeaturesByFilter = (
  searchParams: SearchParams
): string[] | null => {
  const featuresValue: string[] | null = [];

  const otherFeatures = [
    {
      feature: 'arredato',
      enable: Boolean(searchParams.arredato),
      value: OTHER_FEATURES_SEARCH_VALUE.FURNISHED,
    },
    {
      feature: 'balconeOterrazzo',
      enable: Boolean(searchParams.balconeOterrazzo),
      value: searchParams.balconeOterrazzo?.map((el) => {
        return el.charAt(0).toUpperCase() + el.slice(1);
      }),
    },
    {
      feature: 'cantina',
      enable: Boolean(searchParams.cantina),
      value: OTHER_FEATURES_SEARCH_VALUE.BASEMENT,
    },
    {
      feature: 'piscina',
      enable: Boolean(searchParams.piscina),
      value: OTHER_FEATURES_SEARCH_VALUE.POOL,
    },
    {
      feature: 'ascensore',
      enable: Boolean(searchParams.ascensore),
      value: OTHER_FEATURES_SEARCH_VALUE.ELEVATOR,
    },
    {
      feature: 'sistemaAllarmeVigilanza',
      enable: Boolean(searchParams.sistemaAllarmeVigilanza),
      value: OTHER_FEATURES_SEARCH_VALUE.ALARM_SYSTEM,
    },

    {
      feature: 'fumatore',
      enable: Boolean(searchParams.fumatore),
      value: OTHER_FEATURES_SEARCH_VALUE.SMOKERS,
    },
    {
      feature: 'animali',
      enable: Boolean(searchParams.animali),
      value: OTHER_FEATURES_SEARCH_VALUE.ANIMALS,
    },
  ];

  otherFeatures.forEach((item) => {
    if (item.enable && item.value) {
      Array.isArray(item.value)
        ? featuresValue.push(...item.value)
        : featuresValue.push(item.value);
    }
  });

  return featuresValue;
};

export const getTypologiesByFilter = (
  categoryId?: string | number,
  typologyId?: (string | number)[]
) => {
  if (typologyId) {
    const category = categoryId?.toString();
    const typologiesValues: string[] = [];

    typologyId.map((typology) => {
      if (category) {
        if (parseInt(category) === CATEGORY.ROOMS) {
          Object.keys(ROOM_CATEGORIES).filter((key) => {
            if (ROOM_CATEGORIES[key] === typology) {
              return typologiesValues.push(TYPOLOGIES_VALUE[key]);
            }
          });
        }

        if (categoryId === CATEGORIES.NUOVE_COSTRUZIONI) {
          Object.keys(TYPOLOGIES_NEW_BUILDINGS).filter((key) => {
            if (TYPOLOGIES_NEW_BUILDINGS[key] === typology) {
              return typologiesValues.push(TYPOLOGIES_VALUE[key]);
            }
          });
        }

        Object.keys(TYPOLOGIES).filter((key) => {
          if (TYPOLOGIES[key] === typology) {
            return typologiesValues.push(TYPOLOGIES_VALUE[key]);
          }
        });
      }
    });

    return typologiesValues.join('|');
  }
};

export const getContactType = (contactType: PurchaseAndContactEventValue) => {
  switch (contactType) {
    case PurchaseAndContactEventValue.EMAIL:
      return 'email';
    case PurchaseAndContactEventValue.PHONE:
      return 'chiamata';
    case PurchaseAndContactEventValue.VISIT:
      return 'visita';
    default:
      return '';
  }
};

export const formatVisibility = (visibility: Visibility | null | undefined) => {
  if (!visibility) {
    return 'base';
  }

  if (visibility === 'supervetrina') {
    return 'sky';
  }

  return visibility;
};

export const getDesktopScreenSize = (browserWidth: number) => {
  if (browserWidth < 1366) {
    return '<1366';
  }

  if (browserWidth === 1366) {
    return '=1366';
  }

  return '>1366';
};

export const formatTransactionId = (
  purchaseAndContactEventValue: PurchaseAndContactEventValue,
  transactionId: number | null | undefined
) => {
  if (!transactionId) {
    return;
  }

  const transaction_id = transactionId.toString();

  if (
    purchaseAndContactEventValue === PurchaseAndContactEventValue.EMAIL ||
    purchaseAndContactEventValue === PurchaseAndContactEventValue.VISIT
  ) {
    return `e_${transaction_id}`;
  }

  return `c_${transaction_id}`;
};

export const CONTRACT_MAPPING = {
  [CONTACTSTRING.VENDITA]: 'on_sale',
  [CONTACTSTRING.AFFITTO]: 'for_rent',
  [CONTACTSTRING.ASTE]: 'for_auction',
};

const TRACKING_SORT_CRITERION = {
  automatico: 'automatic',
  rilevanza: 'relevance',
  prezzo: 'price',
  superficie: 'surface',
  locali: 'rooms',
  dataModifica: 'modification_date',
  data: 'creation_date',
} as const;

interface FormatRealEstateTrackingParamsReturn {
  'ID Listing': string;
  'Index Number': number | null;
  'Map or List Visualization': string;
  'Page Number': number;
}

export const formatRealEstateTrackingParams = (
  realEstateTrackingData: Record<string, unknown>
): FormatRealEstateTrackingParamsReturn => {
  if (
    realEstateTrackingData['ID Listing'] &&
    realEstateTrackingData['Page Number']
  ) {
    return {
      'ID Listing': realEstateTrackingData['ID Listing'].toString() as string,
      'Index Number': (!realEstateTrackingData['Index Number'] &&
      realEstateTrackingData['Index Number'] !== 0
        ? null
        : Math.abs((realEstateTrackingData['Page Number'] as number) - 1) *
            maxResult +
          (realEstateTrackingData['Page Number'] as number) +
          1) as number | null,
      'Map or List Visualization': (realEstateTrackingData[
        'Map or List Visualization'
      ] || 'n/a') as string,
      'Page Number': realEstateTrackingData['Page Number'] as number,
    };
  }

  return {
    'ID Listing': '0',
    'Index Number': 0,
    'Map or List Visualization': '',
    'Page Number': 0,
  };
};

export const getAvailableState = (availableState?: boolean | null) => {
  if (typeof availableState === 'boolean') {
    if (availableState) {
      return 'Libero';
    }

    return 'Occupato';
  }

  return undefined;
};

export const getRealEstateVisibility = (visibility?: Visibility | null) =>
  visibility === 'supervetrina' ? 'sky' : visibility;

export const getRealEstatePrice = (price?: Price) => {
  if (!price?.visible) {
    return 0;
  }

  if (price.minValue) {
    return price.minValue;
  }

  return price.value;
};

export const getSearchType = (geographyData: GeographySearch | null) => {
  if (!geographyData) {
    return 'Search in this area';
  }

  switch (geographyData.searchType) {
    case 'polygon':
      return 'type' in geographyData.value ? 'Isocronous' : 'Draw';
    case 'circle':
      return 'Isocronous';
    case 'place':
      return geographyData.value.some(
        (entity) =>
          entity.type === ENTITY_TYPE.metro ||
          entity.type === ENTITY_TYPE.metroLine
      )
        ? 'Metro'
        : 'Text';
    default:
      return null;
  }
};

const getIsochronousRange = (geographyData: GeographySearch | null) => {
  if (!geographyData) return null;

  switch (geographyData.searchType) {
    case 'polygon':
      return geographyData.value.time ?? null;
    case 'circle':
      return geographyData.value.radius;
    case 'place':
    default:
      return null;
  }
};

const getIsochronousMethod = (geographyData: GeographySearch | null) => {
  if (!geographyData) return null;
  switch (geographyData.searchType) {
    case 'polygon':
      return geographyData.value.transport ?? null;
    case 'circle':
      return 'circle';
    case 'place':
    default:
      return null;
  }
};

export interface FormatGeographyParamsReturn {
  'Type of Search': string | null;
  'Locality Province Selected': string | null | undefined;
  'Locality City Selected': string | number | null | undefined;
  'Locality Macrozones Selected': number[] | string[] | null | undefined;
  'Locality Microzones Selected': number[] | string[] | null | undefined;
  'Locality Country Selected': string | null | undefined;
  'Locality Region Selected': string | number | null | undefined;
  'Draw - My Location Pressed': null; // TODO: Future developments
  'Isochronous - My Location Pressed': null; // TODO: Future developments
  'Isochronous - Method Selected': string | null;
  'Isochronous - Range Selected': string | number | null;
  'Metro Lines Selected': string[] | null | undefined;
  'Metro Stations Selected': string[] | null | undefined;
}

export interface FormatSearchParamsReturn {
  'Contract Filter': any;
  'Category Filter': string | undefined;
  'Type Filter': (string | number)[] | undefined;
  'Min Price Filter': number[] | string[] | undefined;
  'Max Price Filter': number[] | string[] | undefined;
  'Min Surface Filter': number[] | string[] | undefined;
  'Max Surface Filter': number[] | string[] | undefined;
  'Min Rooms': string | number | number[] | string[] | undefined;
  'Max Rooms': string | number | number[] | string[] | undefined;
  'Number of Bathrooms': string | number | undefined;
  'Property Condition': number[] | string[] | undefined;
  'Property Type': string | number | undefined;
  'Floor Filter': number[] | string[] | undefined;
  'Garage and Parking Space': string[] | number[] | undefined;
  'Heating and Cooling': number[] | string[] | undefined;
  'Garden Type': number[] | string[] | undefined;
  'Energetic Efficiency': (string | number)[] | undefined;
  'Other Features': (string | null)[];
  'Exclude Auctions': boolean;
  'Virtual Tour': boolean;
  'Income Property Filter': boolean;
  'Sort Criteria': string;
  'Sort Order': string | undefined;
}

const mapSelectionMethodToTrackingValue = {
  address: 'Indirizzo',
  pointOnMap: 'Punto su mappa',
};

export const formatGeographyParams = (params: {
  searchParams?: SearchParams;
  geographyData: GeographySearch | null;
}) => {
  const { searchParams, geographyData } = params;

  return geographyData || searchParams
    ? {
        'Type of Search': getSearchType(geographyData),
        'Locality Province Selected': searchParams
          ? searchParams.idProvincia
          : null,
        'Locality City Selected': searchParams ? searchParams.idComune : null,
        'Locality Macrozones Selected': searchParams
          ? searchParams.idMZona
          : null,
        'Locality Microzones Selected': searchParams
          ? searchParams.idQuartiere
          : null,
        'Locality Country Selected': searchParams
          ? searchParams.idNazione
          : null,
        'Locality Region Selected': searchParams
          ? searchParams.fkRegione || searchParams.idRegione
          : null,
        'Draw - My Location Pressed': null, // TODO: Future developments
        'Isochronous - My Location Pressed': null, // TODO: Future developments
        'Isochronous - Method Selected': getIsochronousMethod(geographyData),
        'Isochronous - Range Selected': getIsochronousRange(geographyData),
        'Isocronous Point Method Selected': geographyData?.value[
          'selectionMethod'
        ]
          ? mapSelectionMethodToTrackingValue[
              geographyData.value['selectionMethod']
            ]
          : null,
        'Metro Lines Selected': searchParams ? searchParams.fkMetro : null,
        'Metro Stations Selected': searchParams
          ? searchParams.fkMetroStation
          : null,
        Points: searchParams?.vrt?.split(';').join('|'),
        Circles:
          searchParams && searchParams.centro && searchParams.raggio
            ? formatCircleParams(searchParams.centro, searchParams.raggio)
            : undefined,
      }
    : null;
};

export const formatSearchParams = (searchParams: SearchParams) => ({
  'Contract Filter': CONTRACT_MAPPING[searchParams.idContratto || 1],
  'Category Filter': searchParams.idCategoria,
  'Type Filter': searchParams.idTipologia,
  'Min Price Filter': searchParams.prezzoMinimo,
  'Max Price Filter': searchParams.prezzoMassimo,
  'Min Surface Filter': searchParams.superficieMinima,
  'Max Surface Filter': searchParams.superficieMassima,
  'Min Rooms': bedroomSearchEnabled
    ? searchParams.camereDaLettoMinimo
    : searchParams.localiMinimo,
  'Max Rooms': bedroomSearchEnabled
    ? searchParams.camereDaLettoMassimo
    : searchParams.localiMassimo,
  'Number of Bathrooms': searchParams.bagni,
  'Property Condition': searchParams.stato,
  'Property Type': searchParams.tipoProprieta,
  'Floor Filter': searchParams.fasciaPiano,
  'Garage and Parking Space': searchParams.boxAuto,
  'Heating and Cooling': searchParams.riscaldamenti,
  'Garden Type': searchParams.giardino,
  'Energetic Efficiency': searchParams.classeEnergetica
    ? [searchParams.classeEnergetica]
    : undefined,
  'Other Features': [
    searchParams.balconeOterrazzo?.includes('terrazzo') ? 'terrace' : null,
    searchParams.balconeOterrazzo?.includes('balcone') ? 'balcony' : null,
    searchParams.ascensore ? 'lift' : null,
    searchParams.arredato ? 'furnished' : null,
    searchParams.cantina ? 'cellar' : null,
    searchParams.piscina ? 'pool' : null,
    searchParams.sistemaAllarmeVigilanza ? 'alarm' : null,
    searchParams.animali ? 'pets_welcome' : null,
    searchParams.fumatore ? 'smokers_welcome' : null,
  ].filter(Boolean),
  'Exclude Auctions': searchParams.noAste === '1',
  'Virtual Tour': searchParams.virtualTour === '1',
  'Income Property Filter': searchParams.aReddito === '1',
  'Sort Criteria':
    TRACKING_SORT_CRITERION[getSortCriterion(searchParams.criterio)],
  'Sort Order': searchParams.ordine,
});

export const getRealEstateTrackingData = (
  realEstateId: number,
  trackingData: { realEstateTrackingData: SegmentStoreDetailTrackingData[] }
): SegmentStoreDetailTrackingData => {
  if (Array.isArray(trackingData.realEstateTrackingData)) {
    const realEstate = trackingData.realEstateTrackingData.find(
      (re) => re['ID Listing'] === realEstateId
    );

    if (realEstate) {
      return realEstate;
    }
  }

  return {} as SegmentStoreDetailTrackingData;
};

export const setEntryPoint = (entryPoint: EntryPoint) => {
  setCommonTrackingData({
    'Entry Point': entryPoint,
  });
};

const trackingValues = {
  publishAds: EntryPoint.PUBLISH_AD,
  userSavedListings: EntryPoint.SAVED_LISTINGS,
  userSavedSearch: EntryPoint.SAVED_SEARCHES,
  evalueateBuilding: EntryPoint.EVALUATE_BUILDING,
  userMessages: EntryPoint.MESSAGES,
  userHiddenAds: EntryPoint.HIDDEN_ADS,
  userLastAds: EntryPoint.LAST_ADS,
  userLastSearch: EntryPoint.LAST_RESEARCH,
  international: EntryPoint.ABROAD,
  cityGuide: EntryPoint.BUILDING_PRICES,
  mortgage: EntryPoint.MORTGAGE,
  searchList: EntryPoint.LIST,
  searchMap: EntryPoint.MAP,
  detail: EntryPoint.DETAIL,
  rent: EntryPoint.LIST,
  sell: EntryPoint.LIST,
  auction: EntryPoint.LIST,
  sellBuild: EntryPoint.SELL_BUILDING,
  newConstruction: EntryPoint.LIST,
  agency: EntryPoint.AGENCY_LIST,
  franchising: EntryPoint.FRANCHISING_DETAIL,
  agencyDetail: EntryPoint.AGENCY_DETAIL,
  constructionCompany: EntryPoint.AGENCY_DETAIL,
  pro: EntryPoint.AGENCY_DETAIL,
};

export const getEntryPointFromReferrer = (referrer?: string): string | null => {
  if (!referrer) return null;
  const parseUrl = (url: string): URLParts => {
    const regX =
      /^((?<protocol>[^:\\/?#]+):)?(\/\/(?<hostname>[^\\/:?#]*))?(?::)?(?<port>\d+)?(?<pathname>[^?#]*)(?<search>\?([^#]*))?(?<hash>#(.*))?/;
    const { groups } = url.match(regX) as RegExpMatchArray;

    return groups as never as URLParts;
  };

  const entryPointsUrls: Optional<Record<string, string>> = getProductConfig(
    'trackingReferrerRoutes'
  );

  if (!entryPointsUrls) {
    return null;
  }

  const { pathname, hostname } = parseUrl(referrer);

  const url = Object.entries(entryPointsUrls).find(([, entryPointUrl]) =>
    pathname.match(entryPointUrl)
  );

  if (url) {
    const entryPoint = trackingValues[url[0]];

    if (entryPoint) {
      return entryPoint;
    }

    return null;
  }

  if (hostname === location.hostname) {
    return 'Home';
  }

  return null;
};

export const getEntryPoint = (): Optional<EntryPoint> => {
  return getCommonTrackingData()['Entry Point'] as Optional<EntryPoint>;
};

export const hashEmail = (email: string) => sha256(email);
export const hashPhone = (phone: string) =>
  sha256(formatPhoneWithPrefix(phone));

export const hashEmailAndPhone = ({ email, phone }: HashEmailAndPhoneArgs) => ({
  user_hashedemail: email ? hashEmail(email) : undefined,
  user_hashedphone: phone ? hashPhone(phone) : undefined,
});

export const storeLastEmailAndPhone = ({
  email,
  phone,
}: HashEmailAndPhoneArgs) => {
  if (email) {
    localStorage.setItem(
      EmailAndPhoneStorageKey.LAST_USED_EMAIL,
      hashEmail(email)
    );
  }

  if (phone) {
    localStorage.setItem(
      EmailAndPhoneStorageKey.LAST_USED_PHONE,
      hashPhone(phone)
    );
  }
};

const formatPhoneWithPrefix = (phone: string): string => {
  // with this scenario we cover the cases where the phone number is in the correct
  // format and the prefix is already present
  // this cover both cases where the prefix is made of 2 or 3 digits
  if (phone.startsWith('+')) {
    return phone;
  }

  // with this scenario we cover the cases where the phone number is in the correct format
  // but the prefix is set as 00 instead of +
  if (phone.startsWith('00')) {
    return phone.replace('00', '+');
  }

  //in this scenario we cover the cases when the prefix is missing
  return `+${getProductConfig('phoneCountryCode')}${phone}`;
};

export const formatUserPhone = (
  phone: string | null,
  phone_prefix?: string
) => {
  if (phone_prefix && phone && !phone.includes(phone_prefix)) {
    return phone_prefix + phone;
  } else if (phone) {
    return formatPhoneWithPrefix(phone);
  }

  return undefined;
};

interface GetListingContactContactInfoReturnValue {
  revenue: number;
  contactType: CONTACT_TYPES;
}

export const getListingContactContactInfo = (
  contactType: CONTACT_TYPES
): GetListingContactContactInfoReturnValue => {
  switch (contactType) {
    case CONTACT_TYPES.F2F_VISIT.toString():
      return {
        revenue: REVENUES.F2F_VISIT,
        contactType: CONTACT_TYPES.F2F_VISIT,
      };
    case CONTACT_TYPES.VIRTUAL_VISIT:
      return {
        revenue: REVENUES.IMMOVISITA,
        contactType: CONTACT_TYPES.VIRTUAL_VISIT,
      };
    case CONTACT_TYPES.MESSAGE:
      return {
        revenue: REVENUES.MESSAGE,
        contactType: CONTACT_TYPES.MESSAGE,
      };
    default:
      return {
        revenue: 0,
        contactType: CONTACT_TYPES.UNDEFINED,
      };
  }
};

export const getRealEstateSectionFromEntryPoint = () => {
  const entryPoint = getEntryPoint();

  if (
    entryPoint === EntryPoint.LIST ||
    entryPoint === EntryPoint.AGENCY_DETAIL ||
    entryPoint === EntryPoint.FRANCHISING_DETAIL ||
    entryPoint === EntryPoint.AGENT_DETAIL ||
    entryPoint === EntryPoint.SAVED_LISTINGS
  ) {
    return REAL_ESTATE_TRACKING_SECTIONS.LIST;
  }

  if (entryPoint === EntryPoint.DETAIL) {
    return REAL_ESTATE_TRACKING_SECTIONS.DETAIL;
  }

  return REAL_ESTATE_TRACKING_SECTIONS.MAP;
};
