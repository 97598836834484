import { brazeLogPurchase } from '@pepita-fe/braze';

import type { BrazeLogPurchaseProps } from '../types';

import {
  getAvailableState,
  getEntryPoint,
  getRealEstatePrice,
  getRealEstateVisibility,
} from '../utils';
import { EventName } from '../utils/enums';

export const trackBrazeLogPurchase = (
  brazePurchaseTrackingData: BrazeLogPurchaseProps
) => {
  const {
    revenue,
    listingID,
    contactType,
    receiverType,
    advertiserID,
    price,
    promotedTypeListing,
    availableState,
  } = brazePurchaseTrackingData;

  brazeLogPurchase(EventName.LISTING_CONTACT_REQUEST, revenue, 'EUR', 1, {
    'Entry Point': getEntryPoint(),
    'ID Listing': listingID,
    'Type of Contact': contactType,
    'Type of Receiver': receiverType,
    'ID Receiver': advertiserID,
    'Price Listing': getRealEstatePrice(price),
    'Promoted Type Listing': getRealEstateVisibility(promotedTypeListing),
    'Available State': getAvailableState(availableState),
    revenue,
  });
};
