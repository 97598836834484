import { useCallback, useEffect } from 'react';
import { useAtomCallback, useHydrateAtoms } from 'jotai/utils';

import { seoDataAtom } from '../SEOTags/atoms/SeoDataAtom';

export function HydrateSeoDataAtom({ seoData }) {
  useHydrateAtoms([[seoDataAtom, seoData]]);

  /* 
    useHydrateAtoms hydrates atoms only once during the initial render.
    Rehydration must be paired with an effect that updates the atom's value,
    ensuring the page reflects the current state during client-side navigation.

    More: https://github.com/pmndrs/jotai/discussions/669#discussioncomment-1242082
  */
  const sync = useAtomCallback(
    useCallback(
      (_get, set) => {
        set(seoDataAtom, seoData);
      },
      [seoData]
    )
  );

  useEffect(() => sync(), [sync]);

  return null;
}
