import type { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';

import { expandedMapAtom, showMapAtom } from '../atoms';

import { ErrorBoundary } from 'src/components/ErrorBoundary';

import { ErrorBoundaryMap } from '../ErrorBoundaryMap';

import css from './styles.module.scss';

export function Map({ children }: PropsWithChildren) {
  const showMap = useAtomValue(showMapAtom);
  const expandedMap = useAtomValue(expandedMapAtom);

  return (
    <section
      className={clsx(
        css['in-searchLayoutMap'],
        showMap && css['in-searchLayoutMap--show'],
        expandedMap === 'full-expanded' && 'is-fullExpanded'
      )}
    >
      <div className={css['in-searchLayoutMap__map']} data-cy="search-map">
        <ErrorBoundary FallbackComponent={<ErrorBoundaryMap />}>
          {children}
        </ErrorBoundary>
      </div>
    </section>
  );
}
