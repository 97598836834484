import {
  AGENCY_TYPE,
  CONTRACT,
  SUPERVISOR_TYPE,
} from 'src/constants/real-estate';

import type { Advertiser } from 'src/types/real-estate';

export const getAvailability = (contractType: string): Nullable<string> => {
  if (CONTRACT.SALE === contractType) {
    return 'for_sale';
  } else if (CONTRACT.RENT === contractType) {
    return 'for_rent';
  }

  return null;
};

export const getListingType = (
  contractType: string,
  advertiser?: Advertiser
): string => {
  const listingType: string[] = [];

  if (CONTRACT.SALE === contractType) {
    listingType.push('for_sale');
  } else if (CONTRACT.RENT === contractType) {
    listingType.push('for_rent');
  }

  if (AGENCY_TYPE.AGENCY === advertiser?.agency?.type) {
    listingType.push('by_agent');
  } else if (SUPERVISOR_TYPE.PRIVATE_USER === advertiser?.supervisor?.type) {
    listingType.push('by_owner');
  }

  return listingType.join('_');
};
