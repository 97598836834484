import type { UserCompleteData } from '@indomita-react/auth-provider';
import { emit } from '@pepita/dom';
import { getCookie, setCookie } from '@pepita/lotrash';
import { captureException } from '@sentry/browser';

import { http } from 'src/utils/client/http';

const BRAZE_COOKIE_VERSION = 'v7'; // Version for the cookie (useful if we want to reset tracking)

export const BRAZE_ENTRYPOINTS = {
  HOMEPAGE: 'Home',
  ADS_MANAGER: 'Pubblica Annuncio',
  SAVED_LISTINGS: 'Annunci Salvati',
  SAVED_SEARCHES: 'Ricerche Salvate',
  APPRAISALS: 'Valuta Immobile',
  MESSAGING: 'Messaggistica',
  HIDDEN_LISTINGS: 'Annunci Nascosti',
  RECENT_LISTINGS: 'Annunci Recenti',
  EMAIL: 'Email',
  PUSH_NOTIFICATION: 'Push',
  INTERNATIONAL: 'Estero',
  AGENCIES: 'Agenzie',
  CITY_GUIDE: 'Prezzi immobili',
  MORTGAGES: 'Mutui',
  SEARCH_LIST: 'Lista',
  SEARCH_MAP: 'Mappa',
  LISTING_DETAIL: 'Dettaglio',
} as const;

export const BRAZE_REVENUES = {
  PHONE: 0.5,
  MESSAGE: 0.75,
  IMMOVISITA: 2,
  F2F_VISIT: 5,
} as const;

export const CONTACT_TYPES = {
  PHONE: 'Phone',
  MESSAGE: 'Message',
  VIRTUAL_VISIT: 'Virtual Visit',
  F2F_VISIT: 'f2f Visit',
} as const;

let cacheBrazeInit;
const appboyGetter = () => {
  if (typeof window !== 'undefined') {
    if (!cacheBrazeInit) {
      cacheBrazeInit = new Promise<any>((resolve, reject) => {
        document.documentElement.addEventListener(
          'brazeTracker:disabled',
          () => {
            reject(new Error('Braze not enabled'));
          }
        );

        document.documentElement.addEventListener(
          'brazeTracker:load',
          (event: Event) => {
            // @ts-ignore
            resolve(event.detail);
          }
        );
      });
    }
  }

  return cacheBrazeInit;
};

/**
 * This serves as a way to handle errors that may occur when attempting to initialize the Braze SDK and
 *  ensures that the promise is ready to listen for the initialization event before it is triggered.
 */
appboyGetter()?.catch(() => {
  return;
});

// Manage cookie for braze data on client
type brazeCookie = {
  version: string;
  user: string;
};

const BRAZE_COOKIE_NAME = 'brazeUserDefined';

const getBrazeCookieData = (): brazeCookie => {
  try {
    return JSON.parse(atob(getCookie(BRAZE_COOKIE_NAME)));
  } catch {
    return {
      version: '',
      user: '',
    };
  }
};

const setBrazeCookieData = (userID) => {
  setCookie(
    BRAZE_COOKIE_NAME,
    btoa(
      JSON.stringify({
        version: BRAZE_COOKIE_VERSION,
        user: userID,
      } as brazeCookie)
    ),
    3650
  );
};

//Name: Immobiliare.it for WhatsApp
const SUBSCRIPTION_GROUP_ID = '6046557f-a917-4b74-af8f-d33c8ca31b64';
const setUserData = (
  braze: any,
  userData?: UserCompleteData & {
    userRegistered: boolean;
    typeOfPassword?: string;
  }
) => {
  if (
    !userData ||
    !userData.name ||
    !userData.surname ||
    !userData.email ||
    !userData.language ||
    !Object.prototype.hasOwnProperty.call(userData, 'userRegistered') ||
    !Object.prototype.hasOwnProperty.call(userData, 'enabledNewsletter') ||
    !Object.prototype.hasOwnProperty.call(
      userData,
      'enabledPromotionalEmail'
    ) ||
    !Object.prototype.hasOwnProperty.call(userData, 'enabledProductsEmail')
  ) {
    captureException(new Error('Missing user data for braze'), {
      user: {
        id: getCookie('IMMSESSID'),
      },
      extra: {
        uuid: userData ? userData.uuid : null,
        userData: JSON.stringify(userData),
        brazeUser: JSON.stringify(braze.getUser()),
      },
    });
  }

  if (!userData) return;
  const currentBrazeUser = braze.getUser();

  let enqueued = true;

  enqueued = currentBrazeUser.addToSubscriptionGroup(SUBSCRIPTION_GROUP_ID);

  if (userData.name) {
    enqueued = enqueued && currentBrazeUser.setFirstName(userData.name);
  }

  if (userData.phone) {
    enqueued =
      enqueued &&
      currentBrazeUser.setPhoneNumber(
        `${userData.phonePrefix ?? ''}${userData.phone}`
      );
  }

  if (userData.surname) {
    enqueued = enqueued && currentBrazeUser.setLastName(userData.surname);
  }

  if (userData.email) {
    enqueued = enqueued && currentBrazeUser.setEmail(userData.email);
  }

  if (userData.language) {
    enqueued = enqueued && currentBrazeUser.setLanguage(userData.language);
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'userRegistered')) {
    enqueued =
      enqueued &&
      currentBrazeUser.setCustomUserAttribute(
        'User registered',
        userData.userRegistered
      );
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'enabledNewsletter')) {
    enqueued =
      enqueued &&
      currentBrazeUser.setCustomUserAttribute(
        'enabled_newsletter',
        userData.enabledNewsletter
      );
  }

  if (
    Object.prototype.hasOwnProperty.call(userData, 'enabledPromotionalEmail')
  ) {
    enqueued =
      enqueued &&
      currentBrazeUser.setCustomUserAttribute(
        'enabled_promotional_email',
        userData.enabledPromotionalEmail
      );
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'enabledProductsEmail')) {
    enqueued =
      enqueued &&
      currentBrazeUser.setCustomUserAttribute(
        'enabled_products_email',
        userData.enabledProductsEmail
      );
  }

  if (userData.typeOfPassword) {
    const passwordMapping = new Map([
      ['UNREGISTERED', 'weak'],
      ['REGISTERED_WEAK_PWD', 'weak'],
      ['REGISTERED_CUSTOM_PWD', 'strong'],
    ]);

    enqueued =
      enqueued &&
      currentBrazeUser.setCustomUserAttribute(
        'Type of Password',
        passwordMapping.get(userData.typeOfPassword as string) || ''
      );
  }

  return enqueued;
};

type entrypointsKeys = keyof typeof BRAZE_ENTRYPOINTS;

export type entrypointsType = (typeof BRAZE_ENTRYPOINTS)[entrypointsKeys];

interface BrazeConfig {
  apiKey: string;
  baseUrl: string;
}

const isBrazeEventTrackEnabled = (
  brazeEnabled: boolean,
  brazeConfig: BrazeConfig | undefined
) =>
  brazeEnabled && brazeConfig && Boolean(~document.cookie.search('IMMSESSID'));

export const brazeInitialize = (
  userData: UserCompleteData & { typeOfPassword?: string },
  brazeConfig: BrazeConfig | undefined,
  isEnabled: boolean
) => {
  if (!isBrazeEventTrackEnabled(isEnabled, brazeConfig)) {
    emit(document.documentElement, 'brazeTracker:disabled');

    return;
  }

  // Dynamic import for braze as the sdk cannot be imported on server build
  import('@braze/web-sdk').then((braze: any) => {
    if (!brazeConfig) return;

    // initialize the SDK
    braze.initialize(brazeConfig.apiKey, {
      baseUrl: brazeConfig.baseUrl,
      enableLogging: process.env.NEXT_PUBLIC_BRAZE_LOGGING === 'true',
      allowUserSuppliedJavascript: true,
    });

    braze.automaticallyShowInAppMessages();
    braze.openSession();

    emit(document.documentElement, 'brazeTracker:load', {
      detail: braze,
    });

    // optionally set the current user's External ID
    const brazeCookie = getBrazeCookieData();
    const userID = userData.uuid;

    if (
      userID &&
      (brazeCookie.version !== BRAZE_COOKIE_VERSION ||
        brazeCookie.user !== userID)
    ) {
      braze.changeUser(userID);
      const wasSuccessful = setUserData(braze, {
        ...userData,
        userRegistered: true,
      });

      if (wasSuccessful) {
        http
          .post('/services/account/checkInThirdPartyService.php', {
            body: JSON.stringify({
              thirdParty: 'braze',
            }),
          })
          .json()
          .then(() => {
            setBrazeCookieData(userID);
          });
      }
    }
  });
};

/**
 * This function sets the custom user attribute 'Type of Password' to 'strong' for the current user
 */
export const strongUserConversionBraze = () => {
  appboyGetter()
    ?.then((braze) => {
      const currentBrazeUser = braze.getUser();

      currentBrazeUser?.setCustomUserAttribute('Type of Password', 'strong');
    })
    ?.catch(() => {
      return;
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const brazeLogCustomEvent = <T extends Record<string, any>>(
  eventName: string,
  eventProperties?: T
) => {
  appboyGetter()
    ?.then((braze) => {
      braze.logCustomEvent(eventName, eventProperties);
    })
    ?.catch(() => {
      return;
    });
};

export const brazeLogPurchase = (
  productId: string,
  price: number,
  currencyCode?: string,
  quantity?: number,
  purchaseProperties?: object
): void => {
  appboyGetter()
    ?.then((braze) => {
      braze.logPurchase(
        productId,
        price,
        currencyCode,
        quantity,
        purchaseProperties
      );
    })
    ?.catch(() => {
      return;
    });
};
