import type { PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';

import { expandedMapAtom } from '../atoms';

import css from './styles.module.scss';

export const ResultsWrapper = forwardRef<HTMLElement, PropsWithChildren>(
  ({ children }, ref) => {
    const expandedMap = useAtomValue(expandedMapAtom);

    return (
      <section
        ref={ref}
        className={clsx(
          css['in-searchLayoutResultsWrapper'],
          expandedMap === 'expanded' &&
            css['in-searchLayoutResultsWrapper--collapsed'],
          expandedMap === 'full-expanded' &&
            css['in-searchLayoutResultsWrapper--hidden']
        )}
      >
        {children}
      </section>
    );
  }
);

ResultsWrapper.displayName = 'ResultsWrapper';
