import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

const Context = createContext<Record<string, boolean>>({});

export function EnvFeatureToggleProvider({
  initialFeatures,
  children,
}: {
  initialFeatures: Record<string, boolean>;
  children?: ReactNode;
}) {
  const [features, setFeatures] =
    useState<Record<string, boolean>>(initialFeatures);

  useEffect(() => {
    setFeatures(initialFeatures);
  }, [initialFeatures]);

  return <Context.Provider value={features}>{children}</Context.Provider>;
}

export const useEnvFeatureToggleEnabled = () => {
  const ctx = useContext(Context);

  return useCallback((feature: string) => Boolean(ctx[feature]), [ctx]);
};
