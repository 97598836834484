import { join } from '@pepita/querystring';

import { DISCOVER_ITEM_TYPES } from 'src/types/homepage';

export const urls = {
  externalBaseUrl: 'https://services.immotop.lu',
  publishAdsUrl: '/utente/annuncio/nuovo',
  publishedAdsLandingUrl: '/advertise-property/',
  publishAdsUrlFooter: '/advertise-private-property/',
  evaluateBuildingUrl: '/estimation/',
  houseFinancingUrl: '/financement/',
  cityGuideUrl: '/prix-immobilier/',
  searchAgenciesUrl: '/agences-immobilieres/',
  agencyUrl: '/agences-immobilieres/',
  whoWeAreUrl: '/info/about-us/',
  antiScamUrl: '/info/scam-prevention-tips/',
  contactUsUrl: '/info/contact-us/',
  publishAdUrl: '/info/promote-real-estate-properties/',
  workWithUsUrl: '/info/jobs/',
  advertisingUrl: '/info/advertising/',
  leaderUrl: '/info/traffic/',
  newsUrl: '/news/',
  dossiersUrl: '/dossiers/',
  magazineUrl: '/magazine/',
};

export const headerUrls = [
  ...(urls.searchAgenciesUrl
    ? [
        {
          text: 'header_vert_agencies',
          url: urls.searchAgenciesUrl,
        },
      ]
    : []), // i18n-extract-keys ["header_vert_agencies"]
  ...(urls.cityGuideUrl
    ? [
        {
          text: 'ftr_anchor_house_prices',
          url: urls.cityGuideUrl,
          isClientSideNavigation: true,
        },
      ]
    : []), // i18n-extract-keys ["ftr_anchor_house_prices"]
  ...(urls.evaluateBuildingUrl
    ? [
        {
          text: 'ftr_slim_appraise_house',
          url: urls.evaluateBuildingUrl,
          external: true,
        },
      ]
    : []), // i18n-extract-keys ["ftr_slim_appraise_house"]
  ...(urls.houseFinancingUrl
    ? [
        {
          text: 'ftr_anchor_house_financing',
          url: urls.houseFinancingUrl,
          external: true,
          externalUrl: 'https://pro.immotop.lu',
        },
      ]
    : []), // i18n-extract-keys ["ftr_anchor_house_financing"]
];

export const alternateHeaderUrls = [
  ...(urls.searchAgenciesUrl
    ? [
        {
          text: 'header_vert_agencies',
          url: urls.searchAgenciesUrl,
        },
      ]
    : []), // i18n-extract-keys ["header_vert_agencies"]
  ...(urls.cityGuideUrl
    ? [
        {
          text: 'ftr_anchor_house_prices',
          url: urls.cityGuideUrl,
          isClientSideNavigation: true,
        },
      ]
    : []), // i18n-extract-keys ["ftr_anchor_house_prices"]
  ...(urls.evaluateBuildingUrl
    ? [
        {
          text: 'ftr_slim_appraise_house',
          url: urls.evaluateBuildingUrl,
          external: true,
        },
      ]
    : []), // i18n-extract-keys ["ftr_slim_appraise_house"]
  ...(urls.houseFinancingUrl
    ? [
        {
          text: 'ftr_anchor_house_financing',
          url: urls.houseFinancingUrl,
          external: true,
          externalUrl: 'https://pro.immotop.lu',
        },
      ]
    : []), // i18n-extract-keys ["ftr_anchor_house_financing"]
];

export const discoverUrls = [
  {
    title: 'feature_strip_draw_on_map_title',
    text: 'feature_strip_draw_on_map_text',
    action: 'feature_strip_draw_on_map_act',
    image: '',
    url: '',
    type: DISCOVER_ITEM_TYPES.DRAW_ON_MAP,
  }, // i18n-extract-keys ["feature_strip_draw_on_map_title", "feature_strip_draw_on_map_text", "feature_strip_draw_on_map_act"]
  {
    title: 'feature_strip_evaluate_title',
    text: 'feature_strip_evaluate_text',
    action: 'feature_strip_evaluate_act',
    image: '',
    url: urls.evaluateBuildingUrl,
    externalUrl: true,
    type: DISCOVER_ITEM_TYPES.EVALUATION_BUILDING,
  }, // i18n-extract-keys ["feature_strip_evaluate_title", "feature_strip_evaluate_text", "feature_strip_evaluate_act"]
  ...(urls.publishedAdsLandingUrl
    ? [
        {
          title: 'feature_strip_publish_title',
          text: 'feature_strip_publish_text_paid',
          action: 'feature_strip_publish_act',
          image: '',
          url: join(urls.publishedAdsLandingUrl, 'from=services'),
          type: DISCOVER_ITEM_TYPES.PUBLISH_AD,
        },
      ]
    : []), // i18n-extract-keys ["feature_strip_publish_title", "feature_strip_publish_text_paid", "feature_strip_publish_act"]
];
