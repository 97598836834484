import type { PropsWithChildren } from 'react';
import Head from 'next/head';

import { capitalize } from 'src/utils/string';

export interface ProductHeadProps {
  domain: string;
  assets: {
    maskIcon?: string;
    shortcutIcon?: string;
    appleTouchIcon?: string;
    favicons?: {
      url: string;
      width: number;
      height: number;
      type: string;
      rel: string;
    }[];
    themecolor?: string;
  };
}

function ProductHead({
  domain,
  children,
  assets,
}: PropsWithChildren<ProductHeadProps>) {
  const upperDomainName = capitalize(domain);

  const data = assets && (
    <>
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <meta name="theme-color" content={assets.themecolor} />
      {upperDomainName && (
        <>
          <meta name="apple-mobile-web-app-title" content={upperDomainName} />
          <meta name="application-name" content={upperDomainName} />
        </>
      )}
      {assets.appleTouchIcon && (
        <link rel="apple-touch-icon" href={assets.appleTouchIcon} />
      )}
      {assets.favicons
        ? assets.favicons.map((icon, i) => (
            <link
              key={`favicons_${i}`}
              rel={icon.rel}
              type={icon.type}
              sizes={`${icon.width}x${icon.height}`}
              href={icon.url}
            />
          ))
        : null}
      {assets.maskIcon && (
        <link
          rel="mask-icon"
          href={assets.maskIcon}
          color={assets.themecolor}
        />
      )}
      {assets.shortcutIcon && (
        <link rel="shortcut icon" href={assets.shortcutIcon} />
      )}
    </>
  );

  return (
    <Head>
      {data}
      {children}
    </Head>
  );
}

export default ProductHead;
