import { useEffect } from 'react';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import { useSetAtom } from 'jotai';

import { layoutModeAtom } from '../atoms';

export const useSetLayoutMode = () => {
  const [minScreenWidthLg] = useBreakpoint({ from: 'lg' });
  const setLayoutMode = useSetAtom(layoutModeAtom);

  useEffect(() => {
    if (minScreenWidthLg) {
      setLayoutMode('map-besides-the-list');
    } else {
      setLayoutMode('map-alternates-with-the-list');
    }
  }, [minScreenWidthLg, setLayoutMode]);
};
