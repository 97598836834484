export const capitalize = (string?: string): string => {
  if (typeof string !== 'string') return '';

  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const lowerize = (string?: string): string => {
  if (typeof string !== 'string') return '';

  return string.charAt(0).toLowerCase() + string.slice(1);
};

/**
 * Remove new lines and html tags from given string.
 */
export const plainize = (text: string): string => {
  return text.replace(/\\n/gm, '').replace(/<[^>]*>/gi, '');
};

export const forceNumberValueToString = (value) =>
  typeof value === 'number' ? String(value) : value;

export const thousandSeparator = (numberFormatter) =>
  numberFormatter(1e10).replace(/1|0/g, '').slice(0, 1);

export const decimalSeparator = (numberFormatter) =>
  numberFormatter(1.1).replace(/1/g, '');

export const parseNumber = (string, numberFormatter) => {
  return parseFloat(
    string
      .replace(new RegExp(`\\${thousandSeparator(numberFormatter)}`, 'g'), '')
      .replace(new RegExp(`\\${decimalSeparator(numberFormatter)}`), '.')
      .replace(/[^0-9\\.,]/g, '')
  );
};
