import { useEffect } from 'react';
import type { BotDetectionResult, LoadOptions } from '@fingerprintjs/botd';
import { load } from '@fingerprintjs/botd';
import { useSetAtom } from 'jotai';

import { isBotAtom } from 'src/atoms/isBotAtom';
export const useBotD = () => {
  const setIsBot = useSetAtom(isBotAtom);

  /**
   * Returns a bot protection result
   */
  const botDetect = (botd: (opts?: LoadOptions) => ReturnType<typeof load>) => {
    return botd().then((res) => res.detect());
  };

  useEffect(() => {
    const botd = (opts?: LoadOptions): ReturnType<typeof load> => load(opts);

    botDetect(botd)
      .then((res: BotDetectionResult) => {
        setIsBot(res.bot);

        return;
      })
      .catch(() => setIsBot(false));
  }, [setIsBot]);
};
