const N_DAYS_IN_DECEMBER = 31;

export const START_CHRISTMAS_TIME_DATE = {
  dayOfMonth: 8,
  month: 11,
};

export const CHRISTMAS_DATE = {
  dayOfMonth: 25,
  month: 11,
};

export const END_CHRISTMAS_TIME_DATE = {
  dayOfMonth: 7,
  month: 0,
};

export const isChristmasTime = () => {
  const currDate = new Date();
  const currDayOfMonth = currDate.getDate();
  const currMonth = currDate.getMonth();

  if (currMonth === START_CHRISTMAS_TIME_DATE.month) {
    return currDayOfMonth >= START_CHRISTMAS_TIME_DATE.dayOfMonth;
  }

  if (currMonth === END_CHRISTMAS_TIME_DATE.month) {
    return currDayOfMonth < END_CHRISTMAS_TIME_DATE.dayOfMonth;
  }

  return false;
};

export const getDaysFromChristmas = () => {
  const currDate = new Date();
  const currDayOfMonth = currDate.getDate();
  const currMonth = currDate.getMonth();

  if (currMonth === START_CHRISTMAS_TIME_DATE.month) {
    return Math.abs(CHRISTMAS_DATE.dayOfMonth - currDayOfMonth);
  }

  if (currMonth === END_CHRISTMAS_TIME_DATE.month) {
    return currDayOfMonth + N_DAYS_IN_DECEMBER - CHRISTMAS_DATE.dayOfMonth;
  }

  return undefined;
};
