import { useEffect } from 'react';
import { sendToDataLayerWebVitals } from '@pepita-fe/gtm';
import { onCLS, onFCP, onLCP, onTTFB } from 'web-vitals';
import { onINP } from 'web-vitals/attribution';

export const useWebVitals = () => {
  useEffect(() => {
    // we use setTimeout to split long tasks
    onCLS((metrics) => {
      setTimeout(() => sendToDataLayerWebVitals(metrics), 0);
    });

    onLCP((metrics) => {
      setTimeout(() => sendToDataLayerWebVitals(metrics), 0);
    });

    onFCP((metrics) => {
      setTimeout(() => sendToDataLayerWebVitals(metrics), 0);
    });

    onTTFB((metrics) => {
      setTimeout(() => sendToDataLayerWebVitals(metrics), 0);
    });

    onINP((metrics) => {
      setTimeout(() => sendToDataLayerWebVitals(metrics), 0);
    });
  }, []);
};
