import { useCallback } from 'react';
import { useSegmentContext } from '@indomita-react/segment';
import { brazeLogCustomEvent } from '@pepita-fe/braze';

import type { BrazeLogPurchaseProps } from '../types';

import { EventName } from '../utils/enums';

import { trackBrazeLogPurchase } from '../braze';

export const useTrack = () => {
  // because segment doesn't support braze in app messages we need to utilize
  // the braze native sdk beside segment and split the trackings

  const { track: segmentTrack } = useSegmentContext();

  const track = useCallback(
    (
      event: EventName,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      trackingData?: Record<string, any>,
      brazeLogPurchaseTrackingData?: BrazeLogPurchaseProps
    ) => {
      segmentTrack(event, trackingData);

      // when events aren't "contact type" we must send them to braze using the custom event
      // otherwise we send them using the purchase event to establish the end of the "contact flow"
      // visit request step 2 is hardcoded in the components library so we will continue to send it as custom event
      // and it will live outside this flow
      if (
        event !== EventName.LISTING_CONTACT_REQUEST &&
        event !== EventName.PHONE_CONTACT_REQUEST
      ) {
        brazeLogCustomEvent(event, trackingData);
      } else if (brazeLogPurchaseTrackingData) {
        trackBrazeLogPurchase(brazeLogPurchaseTrackingData);
      }
    },
    [segmentTrack]
  );

  return track;
};
