import type { GA4StoreRealEstateTrackingData } from 'src/tracking/ga/types';
import {
  getGA4RealEstateListTrackingData,
  getGA4RealEstateTrackingData,
} from 'src/tracking/ga/utils/store';
import { getSegmentTrackingData } from 'src/tracking/segment/utils';

export const getMortgageEntryPoint = (position: 'top' | 'up' | 'bottom') =>
  position === 'top' || position === 'up' ? 'Button Top' : 'Widget';

export enum ReportErrorsKey {
  Double = '10',
  WrongCategory = '20',
  WrongPhoneEmail = '30',
  WrongAddressMap = '40',
  NonExistingProperty = '50',
  PossibleScam = '60',
  Other = '80',
  AnswerContacts = '90',
}
export const positionMapping = new Map([
  ['contact_form', 'Contact form'],
  ['additional_options', 'Additional options'],
]);

export const getRealEstateTrackingData = (
  section: 'list' | 'detail' | 'map',
  realEstateId?: number
) => {
  // first scenario is when we are in list page and we want to get the tracking data of a specific real estate
  // we're interacting with
  if (section === 'list') {
    const {
      propertiesCommercialItemValue,
      realEstateCommercialItemValue,
      realEstateTrackingData: realEstateGA4TrackingData,
    } = getGA4RealEstateListTrackingData()?.find(
      (re) => re.realEstateTrackingData?.id === realEstateId
    ) ?? ({} as GA4StoreRealEstateTrackingData);

    return {
      propertiesCommercialItemValue,
      realEstateCommercialItemValue,
      realEstateGA4TrackingData,
      realEstateSegmentTrackingData:
        getSegmentTrackingData().realEstateList?.find(
          (re) => re['ID Listing'] === realEstateId
        ),
    };
  }

  // in this scenario we are in detail/map page
  const {
    propertiesCommercialItemValue,
    realEstateCommercialItemValue,
    realEstateTrackingData: realEstateGA4TrackingData,
  } = getGA4RealEstateTrackingData();

  return {
    realEstateGA4TrackingData,
    propertiesCommercialItemValue,
    realEstateCommercialItemValue,
    realEstateSegmentTrackingData: getSegmentTrackingData().realEstate,
  };
};
