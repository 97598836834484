// This module takes inspiration from https://web.dev/debug-web-vitals-in-the-field/
import { onCLS } from 'web-vitals';

export function getLargestLayoutShiftSource(sources) {
  return sources.reduce((a, b) => {
    return a.node &&
      a.previousRect.width * a.previousRect.height >
        b.previousRect.width * b.previousRect.height
      ? a
      : b;
  });
}

export function trackLayoutShift(callback) {
  onCLS(function ({ entries }) {
    // In some cases there won't be any entries (e.g. if CLS is 0), so we have to check first.
    if (entries.length) {
      callback && entries.forEach(callback);
    }
  });
}
