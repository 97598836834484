import { isProd, isStaging } from 'src/utils/environment';

export const getOrigin = (domainName: string) => {
  if (isProd) {
    return `https://www.${domainName}`;
  }

  if (isStaging) {
    return `https://staging.${domainName}`;
  }

  return '';
};
