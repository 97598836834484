import { useEffect } from 'react';
import { useUserCompleteData } from '@indomita-react/auth-provider';
import { brazeInitialize } from '@pepita-fe/braze';

import { isFeatureEnabled } from 'src/config/features-toggle';
import { getProductConfig } from 'src/config/product';

/**
 *  Using a fake component in order to be able to use the
 * QueryClientProvider without having to use this hook
 * inside all the pages
 * @param {any} [userType] - This is the type of password the user is using. This is used to track the
 * user's password in Landing Page only
 * @returns null
 */

export function BrazeTracker({ registerType }) {
  const userData = useUserCompleteData();

  useEffect(() => {
    if (!userData) return;

    brazeInitialize(
      { ...userData, typeOfPassword: registerType },
      getProductConfig('brazeConfig'),
      isFeatureEnabled('BRAZE')
    );
  }, [registerType, userData]);

  return null;
}
