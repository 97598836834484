import { isFeatureEnabled } from 'src/config/features-toggle';

export const getPlaceSelectionMinZoom = (
  zoomIfCountrySearchIsEnabled: number,
  zoomIfRegionSearchIsEnabled: number,
  defaultMinZoom: number
): number => {
  if (isFeatureEnabled('COUNTRY_SEARCH_ENABLED')) {
    return zoomIfCountrySearchIsEnabled;
  }

  if (isFeatureEnabled('REGION_SEARCH_ENABLED')) {
    return zoomIfRegionSearchIsEnabled;
  }

  return defaultMinZoom;
};
