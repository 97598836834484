import { useEffect, useState } from 'react';
import { DidomiSDK } from '@didomi/react';

import { getProductConfig } from '../../config/product';

import {
  CookieProvider,
  useCheckAnalyticsEnabled,
  useCheckCookieConsentChanged,
  useInitDidomi,
  useSetConsents,
} from './CookieProvider';

export function CookieManager() {
  const [loadDidomi, setLoadDidomi] = useState(false);

  useEffect(() => {
    // Postponing didomi loading in order to split the long task with the client hydration
    // see https://gitlab.pepita.io/indomio/indomita/website-next/-/merge_requests/6225
    requestAnimationFrame(() => {
      setLoadDidomi(true);
    });
  }, []);

  const setConsents = useSetConsents();
  const initDidomi = useInitDidomi();

  return loadDidomi ? (
    <DidomiSDK
      apiKey="ccbb9faf-6f3a-4699-a499-e08dec395373"
      iabVersion={2} // If you want to support the TCF v1∏, don't forget to change this value, even if you selected the TCF v2 in the console. This parameter will load the correct stub in the React Component
      noticeId={getProductConfig('didomiNoticeID')}
      gdprAppliesGlobally={true}
      embedTCFStub={true}
      onReady={(didomi) => {
        // Getting initial consents
        const consents = didomi
          .getUserConsentToken()
          .consents.map((consent) => ({
            purpose: consent.purpose,
            vendors: consent.vendors.filter(
              (vendor) => typeof vendor.id === 'string'
            ),
          }));

        initDidomi(consents, didomi);
      }}
      onConsentChanged={(cwtToken) => {
        // Updating user consents
        setConsents(cwtToken.consents);
      }}
    />
  ) : null;
}

export {
  CookieProvider,
  useCheckAnalyticsEnabled,
  useCheckCookieConsentChanged,
};
