import type { PropsWithChildren } from 'react';

import { PepitaListItem } from 'src/libs/ui/pepita-list';

import css from './styles.module.scss';

export function ListItem({ children }: PropsWithChildren) {
  return (
    <PepitaListItem customClass={css['in-searchLayoutListItem']}>
      {children}
    </PepitaListItem>
  );
}
