export const colors = {
  brand: {
    '01': {
      '900': '#003151',
      '800': '#00406a',
      '700': '#005289',
      '600': '#006ab0',
      '500': '#0074c1',
      '400': '#3390cd',
      '300': '#54a2d5',
      '200': '#8abfe2',
      '100': '#b0d4ec',
      '050': '#e6f1f9',
    },
    '02': {
      '900': '#600008',
      '800': '#7e000a',
      '700': '#a3000d',
      '600': '#d00011',
      '500': '#e50013',
      '400': '#ea3342',
      '300': '#ee5461',
      '200': '#f38a92',
      '100': '#f7b0b6',
      '050': '#fce6e7',
    },
  },
  gray: {
    '900': '#373c47',
    '800': '#4e5564',
    '700': '#60687a',
    '600': '#6f788d',
    '500': '#7c869e',
    '400': '#9fa5b6',
    '300': '#bcc0ca',
    '200': '#d5d7dd',
    '100': '#ebecef',
    '050': '#f5f6f7',
  },
};
