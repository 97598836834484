import type { PropsWithChildren } from 'react';
import { useAtomValue } from 'jotai';

import { showMapAtom } from './atoms';

export function Footer({ children }: PropsWithChildren) {
  const showMap = useAtomValue(showMapAtom);

  return showMap ? null : <>{children}</>;
}
