import type { PropsWithChildren, ReactElement } from 'react';
import { memo, useRef } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { captureException } from '@sentry/nextjs';
import type { CaptureContext } from '@sentry/types';

type ErrorBoundaryProps = {
  FallbackComponent?: ReactElement;
  resetFunction?: () => void;
  sentryExtraData?: CaptureContext;
};

export const ErrorBoundary = memo(
  ({
    FallbackComponent,
    children,
    resetFunction,
    sentryExtraData,
  }: PropsWithChildren<ErrorBoundaryProps>) => {
    const hadAlreadyRendered = useRef(false);

    return (
      <ReactErrorBoundary
        FallbackComponent={({ resetErrorBoundary }) => {
          if (!hadAlreadyRendered.current && resetFunction) {
            hadAlreadyRendered.current = true;
            resetFunction();
            resetErrorBoundary();
          }

          return FallbackComponent || null;
        }}
        onError={(error) => {
          captureException(error, sentryExtraData);
        }}
      >
        {children}
      </ReactErrorBoundary>
    );
  }
);

ErrorBoundary.displayName = 'ErrorBoundary';
