import { useAtomValue } from 'jotai';

import { layoutModeAtom, showMapAtom } from '../atoms';

export const useIsMapShown = () => {
  const showMap = useAtomValue(showMapAtom);
  const layoutMode = useAtomValue(layoutModeAtom);

  return showMap && layoutMode === 'map-alternates-with-the-list';
};
