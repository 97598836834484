import type { NextParsedUrlQuery } from 'next/dist/server/request-meta';

export interface MapQueryParams {
  lat?: string | number;
  lng?: string | number;
  zoom?: number;
}

/**
 * The function `decodeMapCenterQueryParams` takes in query parameters and converts the `mapCenter`
 * parameter from a string to separate `lat` and `lng` parameters.
 *
 * This would cause a strange behaviour in the useRouter hook, which would return an unexpected
 * lat and lng parameters, instead of the expected mapCenter parameter, in query object even if
 * they were not present in the URL.
 *
 * @param {NextParsedUrlQuery} queryParams - The `queryParams` parameter is an object of type
 * `NextParsedUrlQuery`. It represents the query parameters of a URL and contains key-value pairs.
 *
 * @returns the decoded queryParams object.
 */
export const decodeMapCenterQueryParams = (queryParams: NextParsedUrlQuery) => {
  if (queryParams.mapCenter) {
    const [lat, lng] = (queryParams.mapCenter as string).split(',');

    queryParams['lat'] = lat;
    queryParams['lng'] = lng;

    queryParams.mapCenter = undefined;
  }

  return queryParams;
};

/**
 * The function `encodeMapQueryParams` takes in a set of query parameters and returns an object with
 * the map center coordinates and zoom level if all required parameters are present.
 *
 * @param {MapQueryParams} queryParams - The `queryParams` parameter is an object that contains the
 * latitude (`lat`), longitude (`lng`), and zoom level (`zoom`) for a map.
 *
 * @returns an object with two properties: "mapCenter" and "zoom". The value of "mapCenter" is a string
 * concatenation of the "lat" and "lng" values from the "queryParams" object, separated by a comma. The
 * value of "zoom" is the "zoom" value from the "queryParams" object.
 */
export const encodeMapQueryParams = (queryParams: MapQueryParams) => {
  const { lat, lng, zoom } = queryParams;

  if (!lat || !lng || !zoom) {
    return {};
  }

  return {
    mapCenter: `${lat},${lng}`,
    zoom,
  };
};
