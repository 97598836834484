import { useEffect } from 'react';
import { atom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';

export type ExpandedMap = 'expanded' | 'full-expanded' | false;

/**
 * This atom indicates if the map is expanded, full-expanded or not when, on large screens
 * (>=1280px), the list and the map are displayed side by side.
 */
export const expandedMapAtom = atomWithReset<ExpandedMap>(false);

export const useResetExpandedMapAtom = () => {
  const resetExpandedMap = useResetAtom(expandedMapAtom);

  useEffect(() => {
    resetExpandedMap();
  }, [resetExpandedMap]);
};

/**
 * This atom indicates if the map is shown or not when, on small screens
 * (<1280px), the map is displayed over the list.
 */
export const showMapAtom = atomWithReset<boolean>(false);

export const useResetShowMapAtom = () => {
  const resetShowMap = useResetAtom(showMapAtom);

  useEffect(() => {
    resetShowMap();
  }, [resetShowMap]);
};

/**
 * This atom indicates if the map is displayed over the list (<1280px), or the
 * list and the map are displayed side by side (>=1280px). This atom is undefined
 * only at the first render, when the screen size is not yet known.
 */
export const layoutModeAtom = atom<
  Optional<'map-alternates-with-the-list' | 'map-besides-the-list'>
>(
  undefined as unknown as
    | 'map-alternates-with-the-list'
    | 'map-besides-the-list'
);
