import { type PropsWithChildren, useEffect } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { sha256 } from 'js-sha256';

import { useIsFirstRender } from 'src/hooks/useIsFirstRender';

import { setGA4trackingData } from 'src/tracking/ga/utils/store';
import { formatUserPhone, hashEmailAndPhone } from 'src/tracking/utils';
import { EmailAndPhoneStorageKey } from 'src/tracking/utils/enums';

export function UserProvider({ children }: PropsWithChildren) {
  const isFirstRender = useIsFirstRender();
  const { user } = useAuthContext();

  useEffect(() => {
    // when we arrive on client only if we're not we'll take the last used email and phone from localStorage

    if (!user) {
      setGA4trackingData({
        user: {
          ...hashEmailAndPhone({
            email:
              localStorage.getItem(EmailAndPhoneStorageKey.LAST_USED_EMAIL) ??
              undefined,
            phone:
              localStorage.getItem(EmailAndPhoneStorageKey.LAST_USED_PHONE) ??
              undefined,
          }),
        },
      });

      return;
    }

    const phone = formatUserPhone(user.phone, user.phone_prefix);

    setGA4trackingData({
      user: {
        ...hashEmailAndPhone({ email: user.email, phone }),
        user_id: user.uuid,
      },
    });
    if (user.email) {
      localStorage.setItem(
        EmailAndPhoneStorageKey.LAST_USED_EMAIL,
        sha256(user.email)
      );
    }

    if (phone) {
      localStorage.setItem(
        EmailAndPhoneStorageKey.LAST_USED_PHONE,
        sha256(phone)
      );
    }
  }, [user]);

  useEffect(() => {
    // every time the user logs in we'll remove the last used email and phone
    // in this way we'll have a clean history of the users
    if (!isFirstRender && Boolean(user)) {
      localStorage.removeItem(EmailAndPhoneStorageKey.LAST_USED_EMAIL);
      localStorage.removeItem(EmailAndPhoneStorageKey.LAST_USED_PHONE);
    }
  }, [isFirstRender, user]);

  return <> {children} </>;
}
