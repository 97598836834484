import type { HTMLAttributes } from 'react';
import { useEffect } from 'react';

import { useGPTContext } from './GPTProvider';

interface GPTAdSlotProps extends HTMLAttributes<HTMLDivElement> {
  slotId?: string;
  adUnit?: string;
  sizes?: [number, number];
  targetingInfo: Record<string, string | string[]>;
  customClass?: string;
}

export function GPTAdSlot({
  adUnit,
  sizes,
  slotId,
  targetingInfo,
  customClass,
}: GPTAdSlotProps) {
  const [displayedAdSlots, setDisplayedAdSlots] = useGPTContext();

  useEffect(() => {
    if (!adUnit || !slotId || !sizes) return;

    const currAdSlot = displayedAdSlots[slotId];

    if (currAdSlot) return;

    // Register the slot with GPT when the component is loaded.
    googletag.cmd.push(() => {
      const slot = googletag.defineSlot(adUnit, sizes, slotId);

      if (slot) {
        slot.addService(googletag.pubads());
        Object.entries(targetingInfo).forEach(([key, value]) => {
          slot.setTargeting(key, value);
        });
        googletag.display(slot);
        setDisplayedAdSlots((prevSlots) => ({ ...prevSlots, [slotId]: slot }));
      }
    });

    // Clean up the slot when the component is unloaded.
    return () => {
      const currAdSlot = displayedAdSlots[slotId];

      googletag.cmd.push(() => {
        if (currAdSlot) {
          googletag.destroySlots([currAdSlot]);
          setDisplayedAdSlots((prevSlots) => {
            delete prevSlots.slotId;

            return prevSlots;
          });
        }
      });
    };
  }, [
    adUnit,
    displayedAdSlots,
    setDisplayedAdSlots,
    sizes,
    slotId,
    targetingInfo,
  ]);

  return <div id={slotId} className={customClass} />;
}
