import type { LatLng } from '@pepita-canary/leaflet';

import { countryCode, language, locale } from './constants';

import { isDev, isProd } from 'src/utils/environment';
import { getPlaceSelectionMinZoom } from 'src/utils/mapConfig';

export const mapConfig = {
  attribution: 'Immotop.lu',
  language: locale,
  tilesUrlTemplate:
    'https://maps.im-cdn.it/tiles/immobiliare/{z}/{x}/{y}.png?version=2',
  markerTilesUrl: isProd
    ? 'https://tiler.immotop.lu/custom_tiles/points/{z}/{x}/{y}.png'
    : 'https://tiler-staging.immotop.lu/custom_tiles/points/{z}/{x}/{y}.png',
  tiles3DRasterUrlTemplate:
    'https://maps.im-cdn.it/tiles/immobiliare/{z}/{x}/{y}.png?version=2&language=it&scale=2&type=nobuildings',
  tiles3DVectorUrlTemplate:
    'https://maps.im-cdn.it/tiles/immobiliare/{z}/{x}/{y}.mvt?scale=2',
  polygonServiceHost: isProd ? 'https://s1.immotop.lu' : '',
  polygonsServiceVersion: '16',
  polygonNamingServiceHost: '',
  polygonNamingServiceVersion: '1',
  polygonsServiceLanguage: language,
  poiCdnVersion: '1',
  center: {
    lat: 49.735714507509286,
    lng: 6.141746288090375,
  } as LatLng,
  zoom: 8,
  minZoom: 8,
  placeSelectionMinZoom: getPlaceSelectionMinZoom(7, 8, 9),
  placeSelectionMaxZoom: 16,
  drawOnMapMinZoom: 8,
  drawOnMapMaxZoom: 17,
  routeSearch: true,
  geocodingOptions: {
    language: locale,
    host: isDev
      ? 'https://geocoder-api.rete.loc/v1'
      : 'https://geocoder.ekbl.net/v1',
    country: countryCode,
    key: isDev ? 'dev' : 'immotop',
  },
};
