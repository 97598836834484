import type { PropsWithChildren } from 'react';
import type { createStore } from 'jotai';
import { Provider } from 'jotai';

import type { SEOTagsType } from 'src/types/seo';

import { HydrateSeoDataAtom } from './HydrateSeoDataAtom';

interface JotaiProviderProps extends PropsWithChildren {
  store?: ReturnType<typeof createStore>;
  seoData?: SEOTagsType;
}

export function JotaiProvider({
  children,
  store,
  seoData,
}: JotaiProviderProps) {
  return (
    <Provider store={store}>
      <HydrateSeoDataAtom seoData={seoData ?? {}} />
      {children}
    </Provider>
  );
}
