import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';

import { layoutModeAtom } from '../atoms';

import css from './styles.module.scss';

export function Header({ children }: PropsWithChildren) {
  const layoutMode = useAtomValue(layoutModeAtom);

  const isSticky = layoutMode === 'map-besides-the-list';

  // necessary for the sticky position to work properly
  useEffect(() => {
    const root = document.getElementById('__next');

    if (isSticky) {
      root?.classList.add(css['in-rootHeightAuto']);
    }

    return () => {
      root?.classList.remove(css['in-rootHeightAuto']);
    };
  }, [isSticky]);

  return (
    <div
      className={clsx(
        css['in-searchLayoutHeader'],
        isSticky && css['is-sticky']
      )}
    >
      {children}
    </div>
  );
}
