import { useAtomValue } from 'jotai';

import { expandedMapAtom, layoutModeAtom } from '../atoms';

export const useIsMapExpanded = () => {
  const expandedMap = useAtomValue(expandedMapAtom);
  const layoutMode = useAtomValue(layoutModeAtom);

  return expandedMap && layoutMode === 'map-besides-the-list';
};
