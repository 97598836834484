export enum CONTRACT {
  VENDITA = '1',
  AFFITTO = '2',
  ASTE = '14',
  VENDUTO_AFFITTATO = '15',
  ALL = 'all',
}

export enum TYPOLOGIES {
  ATTIVITA_COMMERCIALE = '2',
  LABORATORIO = '3',
  APPARTAMENTO = '4',
  ATTICO_MANSARDA = '5',
  BOX_GARAGE = '6',
  CASA_INDIPENDENTE = '7',
  PALAZZO_STABILE = '10',
  RUSTICO_CASALE = '11',
  VILLA = '12',
  VILLETTA_A_SCHIERA = '13',
  ALTRO = '14',
  ATTIVITA_LICENZA = '16',
  ACCESSO_DISABILI = '17',
  TERRENO = '28',
  TERRENO_AGRICOLO = '106',
  TERRENO_EDIFICABILE = '107',
  TERRENO_NON_EDIFICABILE = '108',
  LOFT_OPEN_SPACE = '31',
  AGRITURISMO = '34',
  BED_BREAKFAST = '35',
  VILLA_VILLETTA = '38',
  VILLA_O_VILLETTA = '47',
  NEGOZIO = '55',
  UFFICIO = '56',
  BOX_AUTO = '57',
  CAPANNONE = '59',
  LOFT = '60',
  MAGAZZINO = '61',
  IMMOBILE = '63',
  BARCA = '65',
  BUNGALOW = '67',
  CHALET_BAITA = '69',
  VACANZE_HOTEL = '71',
  PARCO_VACANZE = '73',
  RESIDENCE = '75',
  ROULOTTE = '77',
  STANZA_CAMERA = '81',
  CASA_PER_FERIE = '83',
  IMMOBILE_IN_ASTA = '87',
  PALAZZI_EDIFICI = '100',
  MAGAZZINI_DEPOSITI = '101',
  GARAGE = '102',
  POSTO_AUTO = '103',
  UFFICIO_STUDIO = '104',
  COWORKING = '105',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  AGRICOLO = '106',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  EDIFICABILE = '107',
  ISOLA = '111',
  STUDIO = '113',
  PALAZZO_HOTEL = '117',
  CAPANNONE_LABORATORIO = '115',
  IMMOBILE_INDUSTRIALE = '116',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  NON_EDIFICABILE = '108',
  CAPANNONI = '109',
  NEGOZI_LOCALI_COMMERCIALI = '1',
  PROJECT = '112',
}

export enum SUBTYPOLOGIES {
  EDIFICABILE_RESIDENZIALE = '67',
  EDIFICABILE_INDUSTRIALE = '70',
  EDIFICABILE_COMMERCIALE = '69',
  TERRENO_AGRICOLO = '71',
}

export enum TYPOLOGIES_NEW_BUILDINGS {
  APPARTAMENTO = '54',
  ATTICO_MANSARDA = '85',
  LOFT = '60',
  VILLA_VILLETTA = '58',
  BOX_AUTO = '57',
  UFFICIO = '56',
  NEGOZIO = '55',
  MAGAZZINO = '61',
  CAPANNONI = '59',
}

export enum TYPOLOGIESV2 {
  APPARTAMENTO = '14',
  ATTICO = '15',
  MANSARDA = '16',
  LOFT = '17',
  RUSTICO = '28',
  CASALE = '29',
  VILLA_UNIFAMILIARE = '23',
  VILLA_BIFAMILIARE = '24',
  VILLA_PLURIFAMILIARE = '25',
  VILLETTA_A_SCHIERA = '26',
  PALAZZO_STABILE = '19',
  BOX_GARAGE = '253',
  POSTO_AUTO = '254',
  PROJECT = '276',
  TERRATETTO_PLURIFAMILIARE = '22',
  TERRATETTO_UNIFAMILIARE = '21',
  OPEN_SPACE = '18',
}

export enum CATEGORIES {
  RESIDENZIALE = '1',
  COMMERCIALE = '2',
  TURISTICO = '3',
  STANZE = '4',
  RICERCA_INTERNAZIONALE = '5',
  NUOVE_COSTRUZIONI = '6',
  ASTE = '14',
  PALAZZI_EDIFICI = '20',
  MAGAZZINI_DEPOSITI = '21',
  GARAGE_POSTI_AUTO = '22',
  UFFICI_COWORKING = '23',
  TERRENI = '24',
  CAPANNONI = '25',
  NEGOZI_LOCALI_COMMERCIALI = '26',
  NEW_BUILDINGS = '27',
}

export const VERTICALS = {
  ...CATEGORIES,
  AGENZIA: '7',
  ATTIVITA: '13',
} as const;

// import from website but change with string value
export enum ROOMS_CONSTANTS {
  ROOM_CATEGORY_NOT_COMPLETE = 'not-complete',
  ROOM_CATEGORY_COMPLETE = 'complete',
}

// Inside table tipologiaStanza
export enum ROOM_TYPOLOGIES {
  STANZA_SINGOLA = '1',
  STANZA_DOPPIA = '2',
  STANZA_TRIPLA = '3',
  STANZA_MULTIPLA = '4',
}

// Not found inside DB...
export enum ROOM_CATEGORIES {
  POSTO_LETTO = '1',
  COMPLETA = '2',
}

// Not found inside DB...
export enum SHOPS {
  'MURA' = '1',
  'ATTIVITA' = '2',
  'LABORATORIO' = '3',
  'MURATT' = '4', // attivita' + locale
  'ATTLAB' = '5', // attivita' + laboratorio
  'MURLAB' = '6', // locale + laboratorio
  'MURATTLAB' = '7', // locale + laboratorio + attivita'
}

export const NEW_COMMERCIAL_MAPPING = {
  '1': SHOPS.MURA,
  '2': SHOPS.LABORATORIO,
  '3': SHOPS.ATTIVITA,
};
