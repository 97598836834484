/**
 * Valutare l'utilizzo diretto delle stringhe anziché l'enum: 'rent' === contract,
 */
export enum CONTRACT {
  SALE = 'sale',
  RENT = 'rent',
  AUCTION = 'auction',
}

export enum CATEGORY {
  RESIDENTIAL = 1,
  COMMERCIAL = 2,
  HOLIDAY = 3,
  ROOMS = 4,
  NEW_CONSTRUCTIONS = 6,
  AUCTION = 12,
  TERRAIN = 10,
  PALACE = 20,
  DEPOSITS = 21,
  BOX_GARAGE = 22,
  COWORKING = 23,
  LANDS = 24,
  SHEDS = 25,
  SHOPS = 26,
}

export enum LISTING_TYPE {
  AD = 'ad',
  PROJECT = 'project',
  AUCTION = 'auction',
  COWORKING = 'coworking',
  ESTATE = 'estate',
}

export enum FEATURE {
  ROOM_WITH_INTERNET = 2,
  TENNIS_COURT = 7,
  INTERNAL_EXPOSITION = 8,
  EXTERNAL_EXPOSITION = 9,
  GYM = 10,
  ROOM_WITH_BALCONY = 13,
  HANDICAP_ACCESS = 17,
  BATHROOM_HANDICAP_ACCESS = 24,
  COMMON_ROOM = 41,
  WAITING_ROOM = 44,
  HAIRDRESSER = 59,
  NURSERY = 60,
  FITNESS_ROOM = 61,
  DOUBLE_ENTRANCE = 76,
  GARDEN = 525,
  KITCHEN = 16,
}

export enum CONDITION {
  NEW_OR_UNDER_CONSTRUCTION = '1',
  GOOD_OR_LIVABLE = '2',
  OTTIMO_RISTRUTTURATO = '6',
  RENOVATE = '5',
}

export enum FLOOR_TYPE {
  GROUND = '10',
  INTERMEDIATE = '20',
  TOP = '30',
}

export enum HEATING_TYPE {
  AUTONOMOUS = '1',
  CENTRALIZED = '2',
  AIR_CONDITIONING = '10',
}

export enum GARDEN_TYPE {
  PRIVATE = '10',
  SHARED = '20',
}

export enum GARAGE_TYPE {
  SINGLE = '1',
  DOUBLE = '3',
  PARKING_SPACE = '4',
}

export enum AGENCY_TYPE {
  AGENCY = 'agency',
  PRO = 'pro',
  CONSTRUCTOR = 'constructor',
  COURT = 'court',
}

export enum SUPERVISOR_TYPE {
  PRIVATE_USER = 'user',
  DELEGATE = 'delegate',
  AGENCY_AGENT = 'agent',
  CUSTOM = 'custom',
}

export enum ADVERTISER_CUSTOM_TYPE {
  ROCK_AGENT = 'rock-agent',
  UNICREDIT = 'unicredit',
  UBI = 'ubi',
  TECNOMEDIA = 'tecnomedia',
  TRIBUNALE_MODENA = 'tribunale-modena',
}

export enum AGENCY_VISIBILITY {
  PAID = 'paid',
  FREE = 'free',
  DEFAULTING = 'defaulting',
  LIGHT = 'light',
}

export enum LOCATION_MARKER {
  AREA = 'only_area',
  MARKER = 'marker',
  NO_MAP = 'no_map',
}

export enum DOCUMENT_TYPES {
  SPECIFICATIONS = 'capitolato',
  PROJECT_DESCRIPTION = 'descrizione progetto',
  PLANIMETRY = 'planimetria',
  APE = 'ape',
}

export enum REAL_ESTATE_STATES {
  ACTIVE = 1,
  SOLD_RENTED = 3,
}

export enum ConstitutionTypes {
  ACCESSORY = 'accessory',
  MAIN = 'main',
}

export enum MORTGAGE_DURATION {
  TEN = 10,
  FIFTEEN = 15,
  TWENTY = 20,
  TWENTYFIVE = 25,
  THIRTY = 30,
}

export enum AUCTION_SALE_STATE {
  PARTICIPABLE = 1,
  NOT_PARTICIPABLE = 2,
  DESERT_AUCTION = 3,
  SUSPENDED = 4,
}

export enum WORKSTATION_TYPE {
  PRIVATE_OFFICE = 1,
  MEETINGS = 2,
  FIXED_WORKSTATION = 3,
  MOBILE_WORKSTATION = 4,
}

export enum STATE {
  ACTIVE = 1,
  SOLD = 3,
}
