import type { SectionName } from 'src/tracking/utils/enums';

import type {
  GA4Store as GA4StoreType,
  GA4StoreAgencyTrackingData,
} from '../types';

let GA4Store: GA4StoreType = {
  agency: undefined,
  agencyList: undefined,
  realEstate: {
    // those are global data for the real estate and should be send for EVERY events
    // also this separation is needed because when we came from the list we don't have the real estate commercial data
    realEstateTrackingData: undefined,
    // those two are the commercial items for the real estate and should be only when they are needed (for example when the ads will be hide by the user)
    propertiesCommercialItemValue: undefined,
    realEstateCommercialItemValue: undefined,
  },
  realEstateList: undefined,
  user: undefined,
  section_name: undefined,
};

let GA4AgencyListener: (() => void)[] = [];

export const setGA4trackingData = (trackingData: Partial<GA4StoreType>) => {
  GA4Store = { ...GA4Store, ...trackingData };
};

export const getGA4trackingData = () => GA4Store;

export const getGA4RealEstateTrackingData = () => GA4Store.realEstate;

export const getGA4RealEstateListTrackingData = () => GA4Store.realEstateList;

export const getGA4UserTrackingData = () => GA4Store.user;

export const getGA4SectionName = () => GA4Store.section_name;

export const setGA4SectionName = (sectionName: SectionName) =>
  (GA4Store.section_name = sectionName);

export const getGA4AgencyTrackingData = () => GA4Store.agency;

export const setGA4AgencyTrackingData = (
  agencyData: GA4StoreAgencyTrackingData,
  notify = false
) => {
  GA4Store = {
    ...GA4Store,
    agency: {
      ...agencyData,
    },
  };
  if (notify) {
    notifyAgencyTrackingDataChange();
  }
};

export const updateGA4AgencyTrackingData = (
  agencyData: Partial<GA4StoreAgencyTrackingData>,
  notify = false
) => {
  GA4Store = {
    ...GA4Store,
    agency: {
      ...(GA4Store.agency as GA4StoreAgencyTrackingData),
      ...agencyData,
    },
  };
  if (notify) {
    notifyAgencyTrackingDataChange();
  }
};

export const subscribeGA4AgencyTrack = (listener: () => void) => {
  GA4AgencyListener = [...GA4AgencyListener, listener];

  return () => {
    GA4AgencyListener = GA4AgencyListener.filter((l) => l !== listener);
  };
};

const notifyAgencyTrackingDataChange = () => {
  // this is very ugly but can help with the actual implementation that we have on real estate agency list
  setTimeout(() => {
    for (const listener of GA4AgencyListener) {
      listener();
    }
  });
};
