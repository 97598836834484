import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { IDidomiObject } from '@didomi/react';
import { useRouter } from 'next/router';

import { isFeatureEnabled } from 'src/config/features-toggle';

type ConsentType = {
  purpose:
    | 'cookies'
    | 'improve_products'
    | 'market_research'
    | 'measure_content_performance'
    | 'measure_ad_performance'
    | 'select_personalized_content'
    | 'create_content_profile'
    | 'select_personalized_ads'
    | 'create_ads_profile'
    | 'select_basic_ads';
  vendors: [{ id: string; status: boolean }];
};

type CookieContext = {
  consents: ConsentType[];
  didomi?: IDidomiObject;
  setConsentChoices: (consents: ConsentType[]) => void;
  setDidomi: (didomi: IDidomiObject) => void;
};

export type Vendors = Record<string, { id: string; enabled: boolean }>;

const initialContext = {
  consents: [],
  didomi: undefined,
  setConsentChoices: () => {
    // do nothing.
  },
  setDidomi: () => {
    // do nothing.
  },
};

const Context = createContext<CookieContext>(initialContext);

export function CookieProvider({ children }: { children?: ReactNode }) {
  const [ctx, setCtx] = useState<CookieContext>(initialContext);
  const [didomi, setDidomi] = useState<IDidomiObject | undefined>(undefined);

  const setConsentChoices = useCallback((consents: ConsentType[]) => {
    setCtx((oldConsents) => ({ ...oldConsents, consents }));
  }, []);

  const context = useMemo(
    () => ({ ...ctx, didomi, setConsentChoices, setDidomi }),
    [ctx, setConsentChoices, didomi, setDidomi]
  );

  // We accept all cookies on client-side navigation if the user did not
  // update its consents
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      if (!ctx.consents?.length) {
        didomi?.setUserAgreeToAll();
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [ctx, didomi]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

export const useSetConsents = () => {
  const ctx = useContext(Context);

  return useCallback(
    (consent: ConsentType[]) => ctx.setConsentChoices(consent),
    [ctx]
  );
};

export const useInitDidomi = () => {
  const ctx = useContext(Context);

  return useCallback(
    (consent: ConsentType[], didomi) => {
      ctx.setConsentChoices(consent);
      ctx.setDidomi(didomi);
    },
    [ctx]
  );
};

/**
 * Execute the callback when Didomi has loaded
 */
export const useOnDidomiLoaded = (callback: () => void) => {
  const ctx = useContext(Context);
  const loaded = useRef(false);

  useEffect(() => {
    if (loaded.current || !ctx.didomi) {
      return;
    }

    callback();
    loaded.current = true;
  }, [ctx, loaded]);
};

/**
 * Execute the callback when Didomi has loaded and the user has made their cookie consent selection.
 */
export const useOnConsentsChanged = (callback: (vendors: Vendors) => void) => {
  const { didomi } = useContext(Context);

  /**
   * indicate if the user has doing select consent choices
   * ref: https://developers.didomi.io/cmp/web-sdk/reference/api#isuserstatuspartial
   */
  const isUserConsentStatusPartial = didomi?.isUserConsentStatusPartial();

  useEffect(() => {
    if (
      !isFeatureEnabled('SEGMENT_TRACKING_PARTNERS_COOKIE') ||
      !didomi ||
      isUserConsentStatusPartial === undefined
    ) {
      return;
    }

    //ref doc: https://developers.didomi.io/cmp/web-sdk/reference/api#getcurrentuserstatus
    const vendors: Vendors = didomi.getCurrentUserStatus().vendors;

    callback(vendors);
  }, [callback, didomi, isUserConsentStatusPartial]);
};

export const useCheckVendorConsent = () => {
  const ctx = useContext(Context);

  return useCallback(
    (vendor: string): boolean =>
      Boolean(ctx.didomi?.getUserConsentStatusForVendor(vendor)),
    [ctx]
  );
};

export const useCheckAnalyticsEnabled = () => {
  const checkVendorConsent = useCheckVendorConsent();

  return checkVendorConsent('c:googleana-4TXnJigR');
};

export const useCheckCookieConsentChanged = () => {
  const ctx = useContext(Context);

  return ctx?.consents?.length > 0;
};

/**
 * Check if the consent notice is currently displayed.
 * ref: https://developers.didomi.io/cmp/web-sdk/reference/api#notice.isvisible
 * */
export const useIsCookieBannerVisible = () => {
  const { didomi } = useContext(Context);

  return Boolean(didomi?.notice.isVisible());
};
