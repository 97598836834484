import {
  formatVisibility,
  getBooleanString,
  getContractType,
  TrackingDataAdvertiser,
} from 'src/tracking/utils';

import type { Property, RealEstate } from 'src/types/real-estate';
import type { ListProperty, ListRealEstate } from 'src/types/real-estate-list';
import type { PropertyLite, RealEstateLite } from 'src/types/real-estate-lite';
import type { CommercialItemsValue } from '../types';

const getItemId = (
  index: number,
  realEstateId: number,
  propertyId?: string
) => {
  if (!index) {
    return realEstateId;
  }

  return propertyId ? parseInt(propertyId) : undefined;
};

export const getPropertiesCommercialItemsValue = (
  realEstate: Optional<RealEstate | RealEstateLite | ListRealEstate>
): CommercialItemsValue[] => {
  if (!realEstate) {
    return [];
  }

  return realEstate.properties.map(
    (property: Property | PropertyLite | ListProperty, index: number) => ({
      item_id: getItemId(index, realEstate.id, property.id),
      item_name: getContractType(realEstate.contract),
      item_brand: realEstate.advertiser?.agency?.id
        ? TrackingDataAdvertiser.AGENCY
        : TrackingDataAdvertiser.PRIVATE,
      item_category: formatVisibility(realEstate.visibility),
      item_category2: property.typologyGA4Translation,
      item_category3: realEstate.properties[0].location?.nation?.name ?? '',
      item_category4: getBooleanString(realEstate.isProjectLike),
      item_category5: property.location?.city,
      item_variant: realEstate.advertiser?.agency?.id.toString(),
      price: property.price.value ?? undefined,
    })
  );
};
