import { useMemo } from 'react';

import { useQueryParams } from './useQueryParams';

export type WhitelistParamsType = {
  slau?: '1' | '0';
  gclid?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
  utm_id?: string;
  wbraid?: string;
  gbraid?: string;
};

export const WHITELIST_KEYS = [
  'slau',
  'gclid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'utm_id',
  'wbraid',
  'gbraid',
] as const;

export const useWhitelistParams = (): WhitelistParamsType => {
  const query = useQueryParams();

  return useMemo(() => {
    const result = {};

    Object.entries(query).forEach(([key, value]) => {
      if ((WHITELIST_KEYS as readonly string[]).includes(key)) {
        result[key] = value;
      }
    });

    return result;
  }, [query]);
};
