import { memo } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { useAtomValue } from 'jotai';
import Head from 'next/head';

import { seoDataAtom } from './atoms/SeoDataAtom';

import { isFeatureEnabled } from 'src/config/features-toggle';
import { getProductConfig } from 'src/config/product';

import { capitalize } from 'src/utils/string';

/** Add all meta tags to product pages **/
export const SEOTags = memo(() => {
  const productName = capitalize(getProductConfig('domainName'));

  const { trans, translations } = useTranslations();
  const seoData = useAtomValue(seoDataAtom);
  const {
    title,
    keywords,
    canonical,
    alternate,
    breadCrumbsSchema,
    prevPage,
    nextPage,
    facebookSettings,
    description,
    appleItunesApp,
    robots,
  } = seoData || {};

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta name="referrer" content="always" /> {/* TODO DEPRECATED */}
      {canonical && <link rel="canonical" href={canonical} key="canonical" />}
      {alternate &&
        alternate.map((alternateLink, index) => (
          <link
            key={index}
            rel={alternateLink.rel}
            hrefLang={alternateLink.hreflang}
            href={alternateLink.href}
          />
        ))}
      {breadCrumbsSchema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: breadCrumbsSchema }}
        ></script>
      )}
      {prevPage && <link rel="prev" href={prevPage} key="prev" />}
      {nextPage && <link rel="next" href={nextPage} key="next" />}
      {/* TODO add manifest href
            <link rel="manifest" href="" key="manifest" />*/}
      <meta httpEquiv="X-UA-Compatible" content="IE=Edge" key="x-ua" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
      />
      <title>
        {title ??
          (translations
            ? trans('profile_pages_meta_title', {
                params: [productName],
              })
            : '')}
      </title>
      {robots && <meta name="robots" content={robots} />}
      {facebookSettings && (
        <>
          {facebookSettings.title && (
            <meta
              property="og:title"
              content={facebookSettings.title}
              key="og-title"
            />
          )}
          {facebookSettings.description && (
            <meta
              property="og:description"
              content={facebookSettings.description}
              key="og-description"
            />
          )}
          {facebookSettings.image && (
            <meta
              property="og:image"
              content={facebookSettings.image}
              key="og-image"
            />
          )}
          {facebookSettings.prefix && (
            <meta
              property="prefix"
              content={facebookSettings.prefix}
              key="og-prefix"
            />
          )}
          {facebookSettings.appId && (
            <>
              <meta
                property="fb:app_id"
                content={facebookSettings.appId}
                key="og-app-id"
              />
              {facebookSettings.locale && (
                <meta
                  property="og:locale:alternate"
                  content={facebookSettings.locale}
                  key="og-alternate"
                />
              )}

              {facebookSettings.type && (
                <meta
                  property="og:type"
                  content={facebookSettings.type}
                  key="og-type"
                />
              )}
              {facebookSettings.url && (
                <meta
                  property="og:url"
                  content={facebookSettings.url}
                  key="og-url"
                />
              )}

              {facebookSettings.options && (
                <meta
                  property={`${getProductConfig('name').replace(
                    '-',
                    '_'
                  )}:options`}
                  content={facebookSettings.options}
                  key="og-options"
                />
              )}
            </>
          )}
        </>
      )}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {isFeatureEnabled('MOBILE_UPSELLING') && appleItunesApp && (
        <meta
          name="apple-itunes-app"
          content={`app-id=${appleItunesApp.appId}, affiliate-data=${appleItunesApp.affiliateData}, app-argument=${appleItunesApp.appArgument}`}
        />
      )}
    </Head>
  );
});

SEOTags.displayName = 'SEOTags';
