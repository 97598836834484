import { getTrackingData, setTrackingData } from '@indomita-react/segment';

import type { Vendors } from 'src/components/CookieManager/CookieProvider';

import type {
  SegmentStore,
  SegmentStoreAgencyTrackingData,
  SetSegmentStoreAgencyListTrackingDataProps,
} from '../types';

export const setSegmentTrackingData = (trackingData: Partial<SegmentStore>) => {
  const segmentTrackingData = getTrackingData();

  setTrackingData<Partial<SegmentStore>>({
    ...segmentTrackingData,
    ...trackingData,
  });
};

export const getSegmentTrackingData: () => SegmentStore = () => {
  return getTrackingData();
};

export const getSegmentAgencyTrackingData = () => {
  const trackingData = getSegmentTrackingData();

  return trackingData.agency;
};

export const setSegmentAgencyTrackingData = (
  trackingData?: SegmentStoreAgencyTrackingData
) => {
  setSegmentTrackingData({ agency: { ...trackingData } });
};

export const updateSegmentAgencyTrackingData = (
  agencyTrackingData?: Partial<SegmentStoreAgencyTrackingData>
) => {
  const agencyData = getSegmentAgencyTrackingData();

  setSegmentAgencyTrackingData({ ...agencyData, ...agencyTrackingData });
};

export const setSegmentAgencyListTrackingData = (
  agencyList: SetSegmentStoreAgencyListTrackingDataProps[]
) => {
  const formattedAgencyList = agencyList.map((agency) => ({
    'Index Number': agency.indexNumber,
    "Number of Agency's Listings": agency.numberOfAgencyListings,
    Visualization: agency.visualization,
    'Page Number': agency.pageNumber,
    'ID Agency': agency.agencyId,
    'Agency Name': agency.agencyName,
    isCourt: agency.isCourt,
  }));

  setSegmentTrackingData({ agencyList: formattedAgencyList });
};

export const getSegmentAgencyListTrackingData = () => {
  const segmentTrackingData = getTrackingData<SegmentStore>();

  return segmentTrackingData.agencyList;
};

export const getSegmentAgencyFromAgencyListById = (id: number) => {
  const agencyList = getSegmentAgencyListTrackingData();

  return agencyList?.find((agency) => agency['ID Agency'] === id);
};

/**
 * Transforms the didomi vendors property into a format expected by the integration segment object.
 * */
export const getPartnersChoicesSelection = (vendors: Vendors) => {
  const partnersChoices: Record<string, boolean> = {};

  Object.entries(vendors).forEach(([id, value]) => {
    vendorsMapping.get(id)?.forEach((partner) => {
      partnersChoices[partner] = value.enabled;
    });
  });

  return partnersChoices;
};

const vendorsMapping = new Map([
  ['microsoft', ['Bing Ads']],
  ['criteo', ['Criteo App & Web Events']],
  [
    'facebook',
    [
      'Facebook App Events',
      'Facebook Conversions API (actions)',
      'Facebook Pixel',
    ],
  ],
  [
    'google',
    ['Google AdWords New', 'Google Ads Conversions', 'DoubleClick Floodlight'],
  ],
  ['googleana-4TXnJigR', ['Google Analytics 4 Cloud']],
  ['tiktok-KZAUQLZ9', ['TikTok Conversions']],
  ['twitter', ['Twitter Ads']],
  [
    'linkedin-marketing-solutions',
    ['LinkedIn Insight Tag', 'LinkedIn Conversions API'],
  ],
  ['pinterest', ['Pinterest Tag', 'Pinterest Conversions API']],
  ['snapinc-yhYnJZfT', ['Snapchat Conversions API']],
]);
