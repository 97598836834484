import { getProductConfig } from 'src/config/product';

import type { Language } from '../types/translations';

const origin = getProductConfig('origin') as string;
const externalBaseUrl = getProductConfig('externalBaseUrl') as string;
const defaultLang = getProductConfig('defaultLocale');

/**
 * Adds the host (domain) and protocol to a given path. Adds the language
 * segment ('/it', '/en', etc.) if a locale is specified.
 *
 * In dev it always returns a url without host and protocol (not absolute).
 *
 * If the url contains the http(s) protocol, it returns the url without changes.
 *
 * @param pathname the path of the url or an absolute url
 * @param locale the current locale string
 * @returns the absolute version of the url with the domain and the protocol
 */
export function absoluteURL(pathname: string, locale?: Language) {
  if (pathname.startsWith('http')) {
    return pathname;
  }

  if (!pathname.startsWith('/')) {
    pathname = `/${pathname}`;
  }

  // Avoid appending two times the current locale
  if (locale && pathname.startsWith(`/${locale}/`)) {
    locale = undefined;
  }

  return `${origin}${locale ? `/${locale}` : ''}${pathname}`;
}

/**
 * Adds the host (domain) and protocol to a given path. Adds the language
 * segment ('/it', '/en', etc.) only if the locale is not the default one.
 *
 * In dev it always returns a url without host and protocol (not absolute).
 *
 * If the url contains the http(s) protocol, it returns the url without changes.
 *
 * @param pathname the path of the url or an absolute url
 * @param locale the current locale string
 * @returns the absolute version of the url with the domain and the protocol
 */
export function alternateAbsoluteURL(
  pathname: string,
  defaultLocale: Language,
  locale: Language
) {
  return absoluteURL(pathname, locale === defaultLocale ? undefined : locale);
}

export function alternateExternalUrl(
  pathname: string,
  defaultLocale: Language,
  locale?: Language,
  externalUrl?: string
) {
  locale = locale === defaultLocale ? undefined : locale;

  if (pathname.startsWith('http')) {
    return pathname;
  }

  if (!pathname.startsWith('/')) {
    pathname = `/${pathname}`;
  }

  // Avoid appending two times the current locale
  if (locale && pathname.startsWith(`/${locale}/`)) {
    locale = undefined;
  }

  if (externalUrl) {
    return `${externalUrl}${locale ? `/${locale}` : ''}${pathname}`;
  }

  if (externalBaseUrl) {
    return `${externalBaseUrl}${locale ? `/${locale}` : ''}${pathname}`;
  }

  return `${origin}${locale ? `/${locale}` : ''}${pathname}`;
}

export const getAlternatePath = (path: string, locale: Language): string =>
  defaultLang && locale !== defaultLang ? `/${locale}${path}` : path;

export const addHttps = (url: string) =>
  url.indexOf('://') === -1 ? `https://${url}` : url;

export const removeOrigin = (url: string) => {
  if (url.indexOf('://') === -1) {
    return url;
  }

  const u = new URL(url);

  return `${u.pathname}${u.search}`;
};

/**
 * Given a url without the protocol and host (the domain), it adds the locale
 * to the url if it's not the default one.
 */
export const addLocale = (
  url: string,
  locale: Language,
  defaultLocale: Language
) => {
  if (locale !== defaultLocale) {
    return `/${locale}${url}`;
  }

  return url;
};

export const replaceUrl = (newUrl: string) => {
  window.history.replaceState(
    //this is necessary to prevent errors on back/forward
    { ...window.history.state, as: newUrl, url: newUrl },
    '',
    newUrl
  );
};

const getFirstLevelDomain = (url: string) => {
  return url.split('.').slice(-2).join('.');
};

export const getValidatedBackurl = (backurl: string | undefined) => {
  if (!backurl) return '/';

  if (backurl.startsWith('http')) {
    const parsedURL = new URL(backurl);
    const domainName = getProductConfig('domainName');

    // compare with the first level's domain
    // to also include management software URLs
    return getFirstLevelDomain(parsedURL.hostname) === domainName
      ? backurl
      : '/';
  }

  return backurl;
};
