import { type PropsWithChildren, useEffect } from 'react';
import { useAtomValue } from 'jotai';

import { layoutModeAtom } from './atoms';

import { Footer } from './Footer';
import { Header } from './Header';
import { List } from './List';
import { ListItem } from './ListItem';
import { Main } from './Main';
import { Map } from './Map';
import { Results } from './Results';
import { ResultsWrapper } from './ResultsWrapper';

function SearchLayoutRoot({ children }: PropsWithChildren) {
  const layoutMode = useAtomValue(layoutModeAtom);

  useEffect(() => {
    const root = document.body;

    if (layoutMode === 'map-alternates-with-the-list') {
      root.style.setProperty('height', '100%');
    }

    return () => {
      root.style.removeProperty('height');
    };
  }, [layoutMode]);

  return <>{children}</>;
}

export const SearchLayout = Object.assign(SearchLayoutRoot, {
  Header,
  Main,
  ResultsWrapper,
  Results,
  Footer,
  Map,
  List,
  ListItem,
});

export * from './atoms';
export * from './hooks';
