import { createAtomicStateStateAtom } from 'src/atoms/atomic-state';

export type HYDRATION_STATUS = 'running' | 'done';

export const hydrationStatusAtom = createAtomicStateStateAtom<HYDRATION_STATUS>(
  {
    key: `AtomHydrationStatus`,
    default: 'done',
    setup(self, { effect, get, set }) {
      effect(() => {
        if (get(self) === 'running') {
          set(self, 'done');
        }
      }, [self]);
    },
  }
);
