import { useCallback, useEffect, useState } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useTranslations } from '@pepita-react/i18n';
import { useSetAtom } from 'jotai';
import Script from 'next/script';

import { displayGOTAtom } from 'src/atoms/displayGOTAtom';

import { useGoogleOneTapTracking } from './hooks/useGoogleOneTapTracking';

import { PepitaSnackbar, usePepitaSnackbar } from 'src/libs/ui/pepita-snackbar';

import type { gotHandleResponse } from 'src/utils/client/initGoogleOneTap';
import { initGoogleOneTap } from 'src/utils/client/initGoogleOneTap';

export function GoogleOneTap() {
  const { user, setIsAuthenticated } = useAuthContext();
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);
  const setDisplayGot = useSetAtom(displayGOTAtom);
  const { trackLoginComplete, trackLoginFailed, trackLoginStart } =
    useGoogleOneTapTracking();
  const { trans } = useTranslations();
  const setSnackBar = usePepitaSnackbar();
  const openSnackBar = useCallback(
    (errorMsg?: Nullable<string>) => {
      setSnackBar(
        <PepitaSnackbar variant="error">
          {errorMsg && errorMsg !== ''
            ? errorMsg
            : trans('toast_generic_error_message')}
        </PepitaSnackbar>
      );
    },
    [setSnackBar, trans]
  );

  const onSuccess = (res: gotHandleResponse) => {
    const loginType = res.trackSSOAccess
      ? 'trackSSOAccess'
      : 'trackSSORegistration';

    setIsAuthenticated(true);
    trackLoginComplete('Google One Tap', res.utente?.uuid, loginType);
  };

  const onFailure = (res?: gotHandleResponse) => {
    openSnackBar(res ? res.error : undefined);
    trackLoginFailed('Google One Tap');
  };

  const trackLoginStartCallback = useCallback(
    () => trackLoginStart('Google One Tap'),
    [trackLoginStart]
  );

  useEffect(() => {
    if (!user && scriptLoaded) {
      initGoogleOneTap(
        setDisplayGot,
        onSuccess,
        onFailure,
        trackLoginStartCallback
      );
    }

    if (user && scriptLoaded) {
      setDisplayGot('hidden');
    }
  }, [user, scriptLoaded]);

  return (
    <Script
      src="https://accounts.google.com/gsi/client"
      async
      defer
      strategy="afterInteractive"
      onLoad={() => setScriptLoaded(true)}
    />
  );
}
