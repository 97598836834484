import type { ComponentProps } from 'react';
import type { AuthForm } from '@indomita-react/auth-form';
import type { AuthModalProps } from '@indomita-react/auth-modal';
import { atom } from 'jotai';

import type { PepitaAlertProps } from 'src/libs/ui/pepita-alert';

export type AuthServiceProvider = 'google' | 'facebook' | 'email' | 'apple';
export type AuthAction =
  | 'login'
  | 'signup'
  | 'passwordRecovery'
  | 'passwordReset'
  | 'entry';

type AuthModal = {
  open: boolean;
  onClose?: () => void;
  onSuccess?: (action: AuthAction, service?: AuthServiceProvider) => void;
  contentType?: AuthModalProps['contentType'];
  data?: {
    email: string;
    alertMessage?: string;
    alertMessageType?: PepitaAlertProps['variant'];
    additionalTrackingData?: ComponentProps<
      typeof AuthForm
    >['additionalTrackingData'];
  };
  showAdvantageInfo?: boolean;
  buttonText?: string;
  agencySignUp?: string;
};

export const authModalAtom = atom<AuthModal>({ open: false });
