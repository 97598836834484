import { deserialize, get, join, serialize } from '@pepita/querystring';

import { replaceUrl } from './url';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deserializeFromUrl = (url: string): Record<string, any> => {
  const qs = get(url);

  if (qs) {
    return deserialize(qs);
  }

  return {};
};

export const serializeIntoUrl = (
  url: string,
  params: Record<string, unknown>
) => {
  const res = join(url, serialize(params));

  return res.endsWith('?') ? res.substring(0, res.length - 1) : res;
};

export const forceArrayValue = <T>(value: T | T[]) =>
  Array.isArray(value) ? value : [value];

export const getPathFromUrl = (url: string): string => {
  return url.split('?')[0];
};

export { deserialize, get, join, serialize };

/**
 * Since boolean params in querystring can be received in different formats,
 * this utils is useful if you need to align them to a real boolean value.
 *
 * @param param undefined, string or boolean
 * @returns the boolean representation of the input value
 */
export const stringToBoolean = (param?: string | boolean): boolean => {
  const p = param?.toString().toLowerCase().trim();

  if (p === 'true' || p === '1') {
    return true;
  }

  return false;
};

export const isQueryParamInUrl = (key: string) => {
  const queryString = window.location.search;
  const hasQueryStringParams = queryString.length > 0;

  return hasQueryStringParams && queryString.includes(key);
};

export const replaceQueryParam = (key: string, value: string) => {
  const queryString = window.location.search.slice(1);
  const newQueryString = queryString
    .split('&')
    .map((paramWithValue) =>
      paramWithValue.includes(key) ? `${key}=${value}` : paramWithValue
    )
    .join('&');

  const newUrl = `${window.location.pathname}?${newQueryString}${window.location.hash}`;

  replaceUrl(newUrl);
};

export const addQueryParam = (key: string, value: string) => {
  if (isQueryParamInUrl(key)) {
    replaceQueryParam(key, value);

    return;
  }

  const isQueryStringEmpty = window.location.search.length === 0;
  const newQueryString = isQueryStringEmpty
    ? `?${key}=${value}`
    : `${window.location.search}&${key}=${value}`;

  const newUrl =
    window.location.pathname + newQueryString + window.location.hash;

  replaceUrl(newUrl);
};

export const removeQueryParam = (key: string) => {
  const queryString = window.location.search.slice(1);
  const newQueryString = queryString
    .split('&')
    .filter((paramWithValue) => !paramWithValue.includes(key))
    .join('&');
  const isNewQueryStringEmpty = newQueryString.length === 0;

  const hash = window.location.hash;
  const isHashEmpty = hash.length === 0;

  const newUrl =
    window.location.pathname +
    (isNewQueryStringEmpty ? '' : `?${newQueryString}`) +
    (isHashEmpty ? '' : hash);

  replaceUrl(newUrl);
};

export const getObjectFromURLSearchParams = (
  params: URLSearchParams
): Record<string, string> => {
  return Object.fromEntries(params.entries());
};
