import type { AgencyPageType } from 'src/repository/agency/types';
import type {
  AgencyData,
  AgentData,
  FranchisingData,
} from 'src/repository/agency/types/formatted-data-types';

import type {
  CATEGORIES,
  CONTRACT,
  TYPOLOGIES,
} from 'src/constants/typologies';

import { getGA4AgencyTrackingData } from 'src/tracking/ga/utils/store';
import {
  CONTRACT_MAPPING,
  getEntryPointFromReferrer,
} from 'src/tracking/utils';
import { EntryPoint, EventName, SectionName } from 'src/tracking/utils/enums';

export const formatAgencySearchParams = (queryParams: {
  contractId?: CONTRACT;
  categoryId?: CATEGORIES;
  typeId?: TYPOLOGIES[];
}) => {
  return {
    'Contract Filter':
      queryParams.contractId && CONTRACT_MAPPING[queryParams.contractId],
    'Category Filter': queryParams.categoryId && queryParams.categoryId,
    'Type Filter': queryParams.typeId,
  };
};

const getAgencyId = (id: number, dataType: AgencyPageType) => {
  switch (dataType) {
    case 'court':
      return '210238';

    case 'agency':
      return id;

    case 'franchising':
      return undefined;
  }
};

const getAgencyName = (name: string, dataType: AgencyPageType) => {
  switch (dataType) {
    case 'court':
      return 'Enti e tribunali';

    case 'agency':
      return name;

    case 'franchising':
      return undefined;
  }
};

export const getGA4AgencyItemId = () => {
  const agencyDataTracking = getGA4AgencyTrackingData();

  if (!agencyDataTracking) {
    return undefined;
  }

  if (agencyDataTracking.dataType === 'franchising') {
    return `g_${agencyDataTracking.groupId}`;
  }

  return `a_${agencyDataTracking.id}`;
};

export const getGA4AgencyInfo = () => {
  const agencyDataTracking = getGA4AgencyTrackingData();

  if (!agencyDataTracking) {
    return undefined;
  }

  const { dataType, name } = agencyDataTracking;

  if (dataType === 'franchising') {
    const { groupId } = agencyDataTracking;

    return {
      group_id: groupId,
      group_name: name,
    };
  }

  const { id } = agencyDataTracking;

  if (dataType === 'agent') {
    const { agency } = agencyDataTracking;

    if (agency) {
      return {
        agent_id: id,
        agent_name: name,
        agency_id: getAgencyId(agency.id, 'agency'),
        agency_name: getAgencyName(agency.name, 'agency'),
      };
    }
  }

  return {
    agency_id: getAgencyId(id, dataType),
    agency_name: getAgencyName(name, dataType),
    ...(dataType === 'court' && {
      courthouse_id: id,
      courthouse_name: name,
    }),
  };
};

export const getGroupInfo = () => {
  const agencyTrackingData = getGA4AgencyTrackingData();

  if (!agencyTrackingData || agencyTrackingData.dataType !== 'franchising') {
    return undefined;
  }

  const { groupId, name } = agencyTrackingData;

  return {
    group_id: groupId,
    group_name: name,
  };
};

export const getEntryPoint = (dataType: AgencyPageType) => {
  if (dataType === 'franchising') {
    return EntryPoint.FRANCHISING_DETAIL;
  }

  if (dataType === 'agent') {
    return EntryPoint.AGENT_DETAIL;
  }

  return EntryPoint.AGENCY_DETAIL;
};

export const getAgencyPageSectionName = (type: AgencyPageType) => {
  if (type === 'franchising') {
    return SectionName.FRANCHISING_CARD;
  }

  if (type === 'agent') {
    return SectionName.AGENT_CARD;
  }

  return SectionName.AGENCY_CARD;
};

export const baseAgencyInfo = (
  agencyTrackingData: AgencyData | FranchisingData | AgentData
) => {
  const { dataType, name } = agencyTrackingData;

  if (dataType === 'franchising') {
    const { groupId, name } = agencyTrackingData;

    return {
      'ID Group': groupId,
      'Group Name': name,
    };
  }

  const { id } = agencyTrackingData;

  if (dataType === 'agent') {
    const {
      agency: { id: agencyId, name: agencyName },
    } = agencyTrackingData;

    return {
      'ID Agent': id,
      'Agent Name': name,
      'ID Agency': agencyId,
      'Agency Name': agencyName,
    };
  }

  const { isCourt } = agencyTrackingData;

  return {
    'ID Agency': id,
    'Agency Name': name,
    isCourt,
  };
};

export const getAgencyEventViewed = (type: AgencyPageType) => {
  if (type === 'franchising') {
    return EventName.AGENCY_GROUP_SHOWN;
  }

  if (type === 'agent') {
    return EventName.AGENT_PAGE_VIEWED;
  }

  return EventName.AGENCY_VIEWED;
};

export const getAgencyViewedEntryPoint = (
  referrer?: string,
  urlParam?: string,
  entryPoint?: string
) => {
  const referrerEntrypoint = getEntryPointFromReferrer(referrer);

  if (referrerEntrypoint === 'Lista' && urlParam === 'list-card') {
    return EntryPoint.CARD_ANNUNCIO;
  }

  return referrerEntrypoint || entryPoint;
};
