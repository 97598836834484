import { memo } from 'react';
import fontWoff2 from '@nugget/core/fonts/Inter/inter-latin-400.woff2';
import Head from 'next/head';

const style = `
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("${fontWoff2}") format("woff2");
  unicode-range: U+0000-007F, U+20AC;
}
`;

export const WebFont = memo(() => (
  <Head>
    <link
      rel="preload"
      href={fontWoff2}
      as="font"
      type="font/woff2"
      crossOrigin=""
    />
    <style dangerouslySetInnerHTML={{ __html: style }} />
  </Head>
));

WebFont.displayName = 'WebFont';
