import clsx from 'clsx';

import type { PepitaListProps } from 'src/libs/ui/pepita-list';
import { PepitaList } from 'src/libs/ui/pepita-list';

import css from './styles.module.scss';

interface ListProps extends PepitaListProps {
  customClass?: string;
}

export function List({ customClass, children }: ListProps) {
  return (
    <PepitaList
      customClass={clsx(css['in-searchLayoutList'], customClass)}
      data-cy="search-layout-list"
    >
      {children}
    </PepitaList>
  );
}
