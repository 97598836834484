import { useCallback, useMemo } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { http } from '@pepita/http';
import { useTranslations } from '@pepita-react/i18n';
import { useRouter } from 'next/router';

import type { ModeSelected } from 'src/components/ListingMapCard/tracking/types';

import type { ContactFormData } from 'src/libs/contact';

import { useSendGA4Event } from 'src/tracking/ga/hooks/useSendGAEvent';
import { formatGA4RealEstateTrackingData } from 'src/tracking/ga/utils/formatRealEstateTrackingData';
import { getPropertiesCommercialItemsValue } from 'src/tracking/ga/utils/getPropertiesCommercialItemsValue';
import { getGA4SectionName } from 'src/tracking/ga/utils/store';
import { useIsMapCollapsed } from 'src/tracking/hooks/useIsMapCollapsed';
import { useTrack } from 'src/tracking/hooks/useTrack';
import type {
  OpenMediaFrom,
  TrackRealEstateSaveArgs,
  TrackRealEstateSaveArgsGA4,
  UserHashedEmailAndPhone,
} from 'src/tracking/types';
import {
  getContractType,
  getEntryPoint,
  getRealEstatePrice,
  getRealEstateVisibility,
  hashEmail,
  hashEmailAndPhone,
  hashPhone,
} from 'src/tracking/utils';
import {
  formatTransactionId,
  getContactType,
  getEntryPointFromReferrer,
  TrackingDataAdvertiser,
} from 'src/tracking/utils';
import {
  CONTACT_TYPES,
  EntryPoint,
  EventName,
  REVENUES,
} from 'src/tracking/utils/enums';
import {
  ContactCategory,
  ContactSubType,
  GATrackingEvent,
  PurchaseAndContactEventValue,
  SectionName,
} from 'src/tracking/utils/enums';
import type {
  FeedbackIssueDataArgs,
  MortgageTrackingData,
  RealEstateNewConstructionViewedArgs,
  TrackMessageContactArgs,
  TrackRealEstateModalPhoneContactArgs,
  TrackRealEstatePhoneContactArgs,
} from '../tracking/type';
import { getRealEstateTrackingData, positionMapping } from '../tracking/utils';

import type { MultiContactCardData } from 'src/types/multi-contact';
import type { RealEstate } from 'src/types/real-estate';

import { getGroupInfo } from 'src/views/Agency/tracking/utils';

const getListingViewedEntryPoint = (referrer?: string, entryPoint?: string) => {
  if (entryPoint === 'related') {
    return EntryPoint.RELATED_LISTINGS;
  }

  const referrerEntrypoint = getEntryPointFromReferrer(referrer);

  if (referrerEntrypoint === 'Lista' && entryPoint === 'map') {
    return EntryPoint.MAP;
  }

  return referrerEntrypoint;
};

export enum REAL_ESTATE_TRACKING_SECTIONS {
  LIST = 'list',
  DETAIL = 'detail',
  MAP = 'map',
}

type UseRealEstateTrackingDataArgs =
  | { section: REAL_ESTATE_TRACKING_SECTIONS.LIST; realEstateId: number }
  | { section: REAL_ESTATE_TRACKING_SECTIONS.DETAIL }
  | { section: REAL_ESTATE_TRACKING_SECTIONS.MAP };

export const useRealEstateTracking = (
  params: UseRealEstateTrackingDataArgs
) => {
  const track = useTrack();
  const { user } = useAuthContext();
  const sendGA4Event = useSendGA4Event();
  const { query } = useRouter();
  const isMapCollapsed = useIsMapCollapsed();
  const { trans } = useTranslations();

  const {
    propertiesCommercialItemValue,
    realEstateCommercialItemValue,
    realEstateGA4TrackingData,
    realEstateSegmentTrackingData,
  } = useMemo(
    () =>
      getRealEstateTrackingData(
        params.section,
        params.section === REAL_ESTATE_TRACKING_SECTIONS.LIST
          ? params.realEstateId
          : undefined
      ),
    [params]
  );

  const trackRealEstateNewConstructionViewed = useCallback(
    ({ projectId }: RealEstateNewConstructionViewedArgs) => {
      track(EventName.LISTING_VIEWED_NEW_CONSTRUCTION, {
        'ID Single Listing': projectId,
      });
    },
    [track]
  );

  const trackBookingButtonPressed = useCallback(
    (realEstate: RealEstate) => {
      track(EventName.BOOKING_BUTTON_PRESSED, {
        'ID Listing': realEstate.id,
      });

      sendGA4Event(GATrackingEvent.PURCHASE, {
        value: 1,
        currency: 'EUR',
        items: getPropertiesCommercialItemsValue(realEstate),
      });

      sendGA4Event(GATrackingEvent.BOOKING, {
        ...formatGA4RealEstateTrackingData({
          realEstate,
          trans,
        }),
        advertiser: TrackingDataAdvertiser.AGENCY,
      });
    },

    [sendGA4Event, track, trans]
  );

  const handlePurchase = useCallback(
    (value: PurchaseAndContactEventValue, transactionId?: number) => {
      const transaction_id = formatTransactionId(value, transactionId);

      if (realEstateGA4TrackingData) {
        sendGA4Event(GATrackingEvent.PURCHASE, {
          ecommerce: {
            currency: 'EUR',
            transaction_id,
            item_id: realEstateGA4TrackingData.id,
            value,
            items: realEstateCommercialItemValue,
          },
        });
      }
    },
    [realEstateCommercialItemValue, realEstateGA4TrackingData, sendGA4Event]
  );

  const handleContact = useCallback(
    (
      value: PurchaseAndContactEventValue,
      contactSubtype?: ContactSubType,
      contactData?: Partial<ContactFormData>
    ) => {
      const transaction_id = formatTransactionId(
        value,
        contactData?.contactId || undefined
      );
      const sectionName = getGA4SectionName();

      if (realEstateGA4TrackingData) {
        let userTrackingData: Optional<UserHashedEmailAndPhone> = {};

        if (user) {
          if (contactData?.email && contactData.email !== user?.email) {
            userTrackingData.user_hashedemail = hashEmail(contactData.email);
          }

          if (contactData?.phone && contactData.phone !== user?.phone) {
            userTrackingData.user_hashedphone = hashPhone(contactData.phone);
          }
        } else {
          userTrackingData = hashEmailAndPhone({
            email: contactData?.email,
            phone: contactData?.phone,
          });
        }

        sendGA4Event(GATrackingEvent.CONTACT, {
          ...realEstateGA4TrackingData,
          ...userTrackingData,
          contact_id: transaction_id,
          contact_category: ContactCategory.REAL_ESTATE,
          contact_type: getContactType(value),
          contact_subtype: contactSubtype,
          mode_selected: 'lista',
          is_map_collapsed: isMapCollapsed ? 'si' : 'no',
          ...(sectionName === SectionName.FRANCHISING_CARD && getGroupInfo()),
        });
      }
    },
    [isMapCollapsed, realEstateGA4TrackingData, sendGA4Event, user]
  );

  const trackListingViewed = useCallback(
    (hasParent: boolean, referrer?: string) => {
      const eventName = hasParent
        ? EventName.LISTING_VIEWED_NEW_CONSTRUCTION
        : EventName.LISTING_VIEWED;

      const entryPoint = getEntryPoint();

      if (realEstateSegmentTrackingData) {
        track(eventName, {
          ...realEstateSegmentTrackingData,
          'Entry Point':
            getListingViewedEntryPoint(
              referrer,
              query.entryPoint as string | undefined
            ) ?? entryPoint,
        });
      }

      sendGA4Event(GATrackingEvent.LISTING_VIEWED, {
        section_name: SectionName.LISTING_DETAIL,
      });

      sendGA4Event(GATrackingEvent.VIEW_ITEM, {
        ecommerce: {
          currency: 'EUR',
          value: 1,
          items: propertiesCommercialItemValue,
        },
      });
    },
    [
      track,
      realEstateSegmentTrackingData,
      query.entryPoint,
      sendGA4Event,
      propertiesCommercialItemValue,
    ]
  );

  const trackMortgageLanding = useCallback(
    (data?: MortgageTrackingData, position?: string, label?: string) => {
      const sectionName = getGA4SectionName();

      track(EventName.MORTGAGE_LANDING_ACCESS, {
        ...realEstateSegmentTrackingData,
        'Mortgage Price': data?.mortgage,
        'Mortgage Tax': data?.rate
          ? Number(data.rate.replace(',', '.'))
          : undefined,
        'Mortgage Duration': data?.year?.value
          ? Number(data.year.value)
          : undefined,
        'Entry Point': data?.entryPoint,
        'Mode Selected': label,
      });

      sendGA4Event(GATrackingEvent.MORTGAGE_ADVICE_LINK, {
        mortgageWidgetPosition: position,
      });

      if (realEstateGA4TrackingData) {
        sendGA4Event(GATrackingEvent.MORTGAGE_CLICK, {
          section_name: sectionName,
          view_type: position,
        });
      }
    },
    [
      realEstateGA4TrackingData,
      realEstateSegmentTrackingData,
      sendGA4Event,
      track,
    ]
  );

  const trackMessagingModalSubmit = useCallback(
    (entryPoint?: string) => {
      track(EventName.MESSAGING_MODAL_SUBMIT, {
        ...realEstateSegmentTrackingData,
        'Entry Point': entryPoint || getEntryPoint(),
      });
    },
    [realEstateSegmentTrackingData, track]
  );

  const trackFeedbackIssue = useCallback(
    (args: FeedbackIssueDataArgs) => {
      const { action, fromPosition, email, name, message, topic } = args;

      const baseEntry = 'Dettaglio annuncio';
      const entryPoint = getEntryPoint() ?? '';
      const entry = `${baseEntry} ${
        entryPoint === 'Mappa' ? entryPoint : ''
      } - ${positionMapping.get(fromPosition)}`;

      if (realEstateSegmentTrackingData) {
        track(
          `${EventName.FEEDBACK_LISTING_DETAIL_ISSUE} ${action}` as EventName,
          {
            ...realEstateSegmentTrackingData,
            'Entry Point': entry,
            'Is User Logged': Boolean(user),
            'Is Empty Message':
              typeof message === 'string' ? !message : undefined,
            'User Name': name,
            Email: email,
            Topic: topic,
          }
        );
      }
    },
    [realEstateSegmentTrackingData, track, user]
  );

  const trackRealEstateSave = useCallback(
    (data: TrackRealEstateSaveArgs) => {
      const { realEstatePrice, realEstateVisibility, saved } = data;

      if (realEstateSegmentTrackingData) {
        track(saved ? EventName.LISTING_SAVED : EventName.LISTING_REMOVED, {
          ...realEstateSegmentTrackingData,
          'Price Listing': getRealEstatePrice(realEstatePrice) ?? undefined,
          'Promoted Type Listing':
            getRealEstateVisibility(realEstateVisibility) ?? undefined,
        });
      }
    },
    [realEstateSegmentTrackingData, track]
  );

  const trackRealEstateAddToWishlist = useCallback(() => {
    if (propertiesCommercialItemValue) {
      sendGA4Event(GATrackingEvent.ADD_TO_WISHLIST, {
        ecommerce: {
          value: 1,
          currency: 'EUR',
          items: propertiesCommercialItemValue,
        },
      });
    }
  }, [propertiesCommercialItemValue, sendGA4Event]);

  const trackRealEstateSaveGA4 = useCallback(
    ({ saved, modeSelected }: TrackRealEstateSaveArgsGA4) => {
      const sectionName = getGA4SectionName();

      sendGA4Event(
        saved
          ? GATrackingEvent.LISTING_SAVED
          : GATrackingEvent.LISTING_SAVED_REMOVED,
        {
          ...(realEstateGA4TrackingData &&
            // Send data because they are not on datalayer
            (sectionName === SectionName.SEARCH_LIST ||
              sectionName === SectionName.LISTINGS_SAVED ||
              sectionName === SectionName.AGENCY_CARD ||
              sectionName === SectionName.FRANCHISING_CARD ||
              sectionName === SectionName.AGENT_CARD) &&
            realEstateGA4TrackingData),
          section_name: sectionName,
          advertiser: realEstateGA4TrackingData?.advertiser,
          view_type: realEstateGA4TrackingData?.origin,
          ...(modeSelected && { mode_selected: modeSelected }),
          is_map_collapsed: isMapCollapsed ? 'si' : 'no',
          ...(sectionName === SectionName.FRANCHISING_CARD && getGroupInfo()),
        }
      );
      if (saved) {
        trackRealEstateAddToWishlist();
      }
    },
    [
      isMapCollapsed,
      realEstateGA4TrackingData,
      sendGA4Event,
      trackRealEstateAddToWishlist,
    ]
  );

  const trackRealEstateSaveFull = useCallback(
    (args: TrackRealEstateSaveArgs) => {
      trackRealEstateSaveGA4(args);
      trackRealEstateSave({
        saved: args.saved,
      });
    },
    [trackRealEstateSave, trackRealEstateSaveGA4]
  );

  const trackGA4ListingHiddenRemoved = useCallback(() => {
    sendGA4Event(GATrackingEvent.LISTING_HIDDEN_REMOVED, {
      section_name: SectionName.LISTING_DETAIL,
    });
  }, [sendGA4Event]);

  const trackListingHidden = useCallback(() => {
    sendGA4Event(GATrackingEvent.LISTING_HIDDEN, {
      section_name: SectionName.LISTING_DETAIL,
    });

    if (realEstateSegmentTrackingData) {
      track(EventName.LISTING_HIDDEN, {
        'ID Listing': realEstateSegmentTrackingData['ID Listing'],
      });
    }
  }, [realEstateSegmentTrackingData, sendGA4Event, track]);

  const trackRealEstateListHide = useCallback(
    (modeSelected?: ModeSelected) => {
      if (realEstateGA4TrackingData) {
        sendGA4Event(GATrackingEvent.LISTING_HIDDEN, {
          section_name: SectionName.SEARCH_LIST,
          ...realEstateGA4TrackingData,
          ...(modeSelected && { mode_selected: modeSelected }),
          is_map_collapsed: isMapCollapsed ? 'si' : 'no',
        });
      }
    },
    [isMapCollapsed, realEstateGA4TrackingData, sendGA4Event]
  );

  const trackRealEstateMultiContact = useCallback(
    (
      itemsSelected: MultiContactCardData[],
      itemSelected: MultiContactCardData,
      index: number,
      contactData?: Partial<ContactFormData>
    ) => {
      const transaction_id = formatTransactionId(
        PurchaseAndContactEventValue.EMAIL,
        contactData?.contactId || undefined
      );

      if (contactData?.contactId) {
        sendGA4Event(GATrackingEvent.PURCHASE, {
          ecommerce: {
            currency: 'EUR',
            transaction_id,
            item_id: itemSelected.pk,
            value: PurchaseAndContactEventValue.EMAIL,
            items: {
              item_id: itemSelected.pk,
              item_name: getContractType(itemSelected.contract),
              item_brand: itemSelected.idAgenzia
                ? TrackingDataAdvertiser.AGENCY
                : TrackingDataAdvertiser.PRIVATE,
              item_variant: itemSelected.idAgenzia,
              price: itemSelected.rawPrice.value,
            },
          },
        });
      }

      let userTrackingData: Optional<UserHashedEmailAndPhone> = {};

      if (user) {
        if (contactData?.email && contactData.email !== user?.email) {
          userTrackingData.user_hashedemail = hashEmail(contactData.email);
        }

        if (contactData?.phone && contactData.phone !== user?.phone) {
          userTrackingData.user_hashedphone = hashPhone(contactData.phone);
        }
      } else {
        userTrackingData = hashEmailAndPhone({
          email: contactData?.email,
          phone: contactData?.phone,
        });
      }

      if (contactData?.contactId) {
        sendGA4Event(GATrackingEvent.CONTACT, {
          contact_id: transaction_id,
          advertiser: itemSelected.idAgenzia
            ? TrackingDataAdvertiser.AGENCY
            : TrackingDataAdvertiser.PRIVATE,
          value: PurchaseAndContactEventValue.EMAIL,
          property_id: itemSelected.pk,
          property_city: itemSelected.comune_nome,
          property_macrozone: itemSelected.macrozona_nome,
          property_rooms: itemSelected.rooms ? itemSelected.rooms : undefined,
          property_baths:
            itemSelected.bathrooms !== '' ? itemSelected.bathrooms : undefined,
          property_floor:
            itemSelected.abbreviated_floor !== ''
              ? itemSelected.abbreviated_floor
              : undefined,
          contact_category: ContactCategory.REAL_ESTATE,
          contact_type: 'multicontatto',
          ...userTrackingData,
        });
      }

      if (itemsSelected.length === index) {
        track(EventName.MULTI_CONTACT_BUTTON_PRESSED, {
          'Number of listings selected': itemsSelected.length,
          'Listings Selected IDs': itemsSelected.filter((item) => item.pk),
        });
      }
    },
    [sendGA4Event, user, track]
  );

  const trackRealEstatePhoneContact = useCallback(
    (data: TrackRealEstatePhoneContactArgs) => {
      const {
        phoneType,
        source,
        listingContactAvailableState,
        price,
        visibility,
        advertiser,
      } = data;

      if (
        realEstateGA4TrackingData &&
        realEstateGA4TrackingData.advertiser === 'agenzia'
      ) {
        http
          .get(`/stats/statsAgenzia.php`, {
            searchParams: {
              idAgenzia: realEstateGA4TrackingData.agency_id,
              typeTrack: 'mostraTelefono',
              annuncio: realEstateGA4TrackingData.id,
              type: 'annuncio',
              fieldTrack: phoneType,
              source,
              output: 'json',
              [Number(new Date()).toString()]: '',
            },
          })
          .json<{ id: number }>()
          .then((res) => {
            handlePurchase(PurchaseAndContactEventValue.PHONE, res.id);
            handleContact(
              PurchaseAndContactEventValue.PHONE,
              phoneType === 'vTel1'
                ? ContactSubType.SMART
                : ContactSubType.NO_SMART,
              { contactId: res.id }
            );
          })
          .catch();
      }

      if (realEstateSegmentTrackingData) {
        track(EventName.PHONE_CONTACT_REQUEST, realEstateSegmentTrackingData, {
          revenue: REVENUES.PHONE,
          availableState: listingContactAvailableState,
          advertiserID: advertiser?.agency?.id.toString(),
          receiverType: advertiser?.agency?.id ? 'Agency' : 'Private',
          listingID: realEstateSegmentTrackingData['ID Listing'],
          promotedTypeListing: visibility ?? undefined,
          price,
          contactType: CONTACT_TYPES.PHONE,
        });
      }
    },
    [
      realEstateGA4TrackingData,
      track,
      realEstateSegmentTrackingData,
      handlePurchase,
      handleContact,
    ]
  );

  const trackRealEstateModalPhoneContact = (
    data: TrackRealEstateModalPhoneContactArgs
  ) => {
    const { advertiser, price, visibility, listingContactAvailableState } =
      data;

    if (!advertiser.supervisor && advertiser.agency?.phones.length === 1) {
      trackRealEstatePhoneContact({
        phoneType: advertiser.agency.phones[0].type,
        source: data.source,
        price,
        visibility,
        advertiser,
        listingContactAvailableState,
      });
    } else if (
      advertiser.supervisor &&
      advertiser.supervisor.type !== 'user' &&
      advertiser.supervisor.phones.length === 1
    ) {
      trackRealEstatePhoneContact({
        phoneType: advertiser.supervisor.phones[0].type,
        source: data.source,
        price,
        visibility,
        advertiser,
        listingContactAvailableState,
      });
    }
  };

  const trackSubmitContact = useCallback(
    ({
      contactData,
      listingContactAvailableState,
      price,
      visibility,
      advertiser,
    }: TrackMessageContactArgs) => {
      if (contactData.contactId) {
        handlePurchase(
          PurchaseAndContactEventValue.EMAIL,
          contactData.contactId
        );
        handleContact(
          PurchaseAndContactEventValue.EMAIL,
          undefined,
          contactData
        );
        if (realEstateSegmentTrackingData) {
          track(
            EventName.MESSAGE_CONTACT_REQUEST,
            {
              'Is Default Message': !contactData.message,
              'Sender Name': contactData.name,
              'Sender Email': contactData.email,
              'Sender Phone Number': contactData.phone,
              'Entry Point': getEntryPoint() ?? '',
              'Page Number': realEstateSegmentTrackingData['Page Number'] || 0,
              'Index Number': realEstateSegmentTrackingData['Index Number'],
              'ID Listing': realEstateSegmentTrackingData['ID Listing'],
              'Map or List Visualization':
                realEstateSegmentTrackingData['Map or List Visualization'],
            },
            {
              contactType: CONTACT_TYPES.MESSAGE,
              advertiserID: advertiser?.agency?.id.toString(),
              listingID: realEstateSegmentTrackingData['ID Listing'],
              receiverType: advertiser?.agency?.id ? 'Agency' : 'Private',
              revenue: REVENUES.MESSAGE,
              availableState: listingContactAvailableState,
              price,
              promotedTypeListing: visibility ?? undefined,
            }
          );
        }
      }
    },
    [handlePurchase, handleContact, realEstateSegmentTrackingData, track]
  );

  const trackGA4SaveNote = useCallback(
    (position?: 'stickyBlock' | 'noteBanner') => {
      const sectionName = getGA4SectionName();

      if (realEstateGA4TrackingData) {
        const viewType = () => {
          if (!position) return;

          return position === 'stickyBlock' ? 'top' : 'bottom';
        };

        sendGA4Event(GATrackingEvent.SAVE_NOTE, {
          section_name: sectionName,
          view_type: viewType(),
        });
        trackRealEstateAddToWishlist();
      }
    },
    [realEstateGA4TrackingData, sendGA4Event, trackRealEstateAddToWishlist]
  );

  const trackNoteSaved = useCallback(
    (
      noteValue: string,
      hasAlreadyANote?: boolean,
      entryPoint?: EntryPoint.BANNER_DETAIL | EntryPoint.NAVIGATION_DETAIL
    ) => {
      if (!realEstateSegmentTrackingData) return;

      track(EventName.NOTE_SAVED, {
        'ID Listing': realEstateSegmentTrackingData['ID Listing'],
        'Already Has A Note': hasAlreadyANote,
        'Entry Point': entryPoint || getEntryPoint(),
        'Note content': noteValue,
      });
    },
    [realEstateSegmentTrackingData, track]
  );

  const trackShareItem = useCallback(
    (medium: string) => {
      sendGA4Event(GATrackingEvent.LISTING_SHARED, {
        medium,
      });
      trackRealEstateAddToWishlist();
    },
    [sendGA4Event, trackRealEstateAddToWishlist]
  );

  const trackMedia = useCallback(
    (eventName: EventName, position: OpenMediaFrom) => {
      if (realEstateSegmentTrackingData) {
        track(eventName, {
          'Opening Point': position,
          'ID Listing': realEstateSegmentTrackingData['ID Listing'],
        });
      }
    },

    [realEstateSegmentTrackingData, track]
  );

  const trackEnergeticEffeciencyExpanded = useCallback(() => {
    if (realEstateSegmentTrackingData) {
      track(EventName.ENERGETIC_EFFICIENCY_EXPANDED, {
        'ID Listing': realEstateSegmentTrackingData['ID Listing'],
      });
    }
  }, [realEstateSegmentTrackingData, track]);

  const trackExpandDescriptionPressed = useCallback(() => {
    if (realEstateSegmentTrackingData) {
      track(EventName.EXPAND_DESCRIPTION_PRESSED, {
        'ID Listing': realEstateSegmentTrackingData['ID Listing'],
      });
    }
  }, [realEstateSegmentTrackingData, track]);

  const trackListingShared = useCallback(() => {
    if (realEstateSegmentTrackingData) {
      track(EventName.LISTING_SHARED, {
        'ID Listing': realEstateSegmentTrackingData['ID Listing'],
      });
    }
  }, [realEstateSegmentTrackingData, track]);

  const trackSurfaceDetailsExpanded = useCallback(() => {
    if (realEstateSegmentTrackingData) {
      track(EventName.SURFACE_DETAILS_EXPANDED, {
        'ID Listing': realEstateSegmentTrackingData['ID Listing'],
      });
    }
  }, [realEstateSegmentTrackingData, track]);

  return {
    trackListingViewed,
    trackMortgageLanding,
    trackMessagingModalSubmit,
    trackRealEstateMultiContact,
    trackFeedbackIssue,
    trackRealEstateSave,
    trackRealEstateSaveGA4,
    trackRealEstateSaveFull,
    trackGA4ListingHiddenRemoved,
    trackRealEstateListHide,
    trackRealEstatePhoneContact,
    trackRealEstateModalPhoneContact,
    trackSubmitContact,
    trackGA4SaveNote,
    handleContact,
    handlePurchase,
    trackShareItem,
    trackExpandDescriptionPressed,
    trackMedia,
    trackListingShared,
    trackSurfaceDetailsExpanded,
    trackNoteSaved,
    trackListingHidden,
    trackRealEstateNewConstructionViewed,
    trackBookingButtonPressed,
    trackEnergeticEffeciencyExpanded,
  };
};
