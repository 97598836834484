import { useMemo } from 'react';
import { useRouter } from 'next/router';

import { deserializeFromUrl } from 'src/utils/querystring';
import { decodeMapCenterQueryParams } from 'src/utils/searchListMapCenterQueryParams';

export function useQueryParams() {
  const { asPath } = useRouter();

  return useMemo(
    () => decodeMapCenterQueryParams(deserializeFromUrl(asPath)),
    [asPath]
  );
}
